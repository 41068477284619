// import React from "react";
import React from "react";
import { ToastContainer } from "react-toastify";
import "./index.css";
// import Root from "./Root";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";

import Home from "./views/homePage";
import Login from "./views/account/loginPage";
import Signup from "./views/account/registration/registerPage";
import Activate from './views/account/registration/activateAccount';
import Dashboard from './views/account/dashboard';
import Referees from "./views/account/referees";
import Profile from "./views/account/profile";
import ResetPassword from "./views/account/password/resetPassword";
import ChangePassword from "./views/account/password/changePassword";
import About from "./views/aboutUs";
import Directors from "./views/boardOfDirectors";

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/material-dashboard.css';
// import './assets/css/material-dashboard.min.css';
// import './assets/js/core/bootstrap.bundle.min.js';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'perfect-scrollbar/dist/perfect-scrollbar';
import './assets/js/material-dashboard';

import axios from "axios";
import { createTheme, NextUIProvider } from "@nextui-org/react";
import { createTheme as materialTheme, ThemeProvider } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import NotFound from "./views/notFound";

if (window.location.origin === "https://youthcoop.ogscofed.coop") {
  axios.defaults.baseURL = "https://youthapi.ogscofed.coop";
} else {
  axios.defaults.baseURL = window.location.origin;
}

let theme2 = materialTheme({
  palette: {
    primary: {
      main: green[700],
    },
    secondary: {
      main: '#820F65',
    },
  },
  typography: {
    fontFamily: "'Fira Sans', sans-serif",
  },
});


const theme = createTheme({
  type: "light",
  theme: {
    colors: {
      secondaryLight: '#E8759D',
      secondaryLightHover: '#D24F8B',
      secondaryLightActive: '#B51F72',
      secondaryLightContrast: '#820F65',
      secondary: '#820F65',
      secondaryBorder:'#9B166D',
      secondaryBorderHover: '#820F65',
      secondarySolidHover: '#68095A',
      secondarySolidContrast: '$white',
      secondaryShadow: '#9B166D',

      primaryLight: '$green300',
      primaryLightHover: '$green400',
      primaryLightActive: '$green500',
      primaryLightContrast: '$green700',
      primary: '$green700',
      primaryBorder:'$green600',
      primaryBorderHover: '$green700',
      primarySolidHover: '$green800',
      primarySolidContrast: '$white',
      primaryShadow: '$green600',
      link: '$820F65'
    },
    fonts: {
      sans: "'Fira Sans', sans-serif",
    },
  },
});

export default function App() {
  
  return (
      <Router>
        <AuthProvider>
            <NextUIProvider theme={theme}>
              <ThemeProvider theme={theme2}>
                <ToastContainer autoClose={6000} draggable={false} closeButton={false} />
                  <Switch>
                    <Route path="/account/password-reset/:uidb64/:token" component={ResetPassword}></Route>
                    <PrivateRoute path="/account/password-change" component={ChangePassword}></PrivateRoute>
                    <Route path="/account/email-verify/:token" component={Activate}></Route>
                    <Route path="/account/login" component={Login}></Route>
                    <Route path="/account/register" component={Signup}></Route>
                    <PrivateRoute path="/account/user/dashboard/profile" component={Profile}></PrivateRoute>
                    <PrivateRoute path="/account/user/dashboard/referees" component={Referees}></PrivateRoute>
                    <PrivateRoute path="/account/user/dashboard" component={Dashboard}></PrivateRoute>
                    <Route path="/directors" component={Directors}></Route>
                    <Route path="/about" component={About}></Route>
                    <Route exact path="/" component={Home}></Route>
                    <Route path="*" component={NotFound}></Route>
                  </Switch>
              </ThemeProvider>
            </NextUIProvider>
          </AuthProvider>
      </Router>
  );
}