import PaystackPop from "@paystack/inline-js";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";

// Paystack function for making REGISTRATION PAYMENT.
function payWithPayStackReg(paymentData, userData, paystackData) {
  let obj = {
    key: paystackData.PUBLIC_KEY,
    email: userData.email,
    amount: checkAmount(paymentData.transaction_amount) * 100,
    ref: paymentData.transaction_id,
    metadata: {
      custom_fields: [
        {
          display_name: "Paid by",
          variable_name: "paid_by",
          value: `${userData.first_name} ${userData.last_name}`,
        },
        {
          display_name: "Category",
          variable_name: "category",
          value: `Registration Payment`,
        },
        {
          display_name: "Paying entity",
          variable_name: "paying_entity",
          value: "Youth COOP",
        },
      ],
    },
    //split_code: "SPL_hwbAvBpH8j",
    callback: function (response) {
      if (response.status === "success") {
        verifyRegistrationPayment(response, paymentData, paystackData);
      } else {
        toast.error(
          "Your transaction could not be processed. Try again or contact support"
        );
      }
    },
  };
  let handler = PaystackPop.setup(obj);
  handler.openIframe();
}

// Paystack function for making any payment
function payWithPayStack(paymentData, userData, paystackData, paymentType) {
  let obj = {
    key: paystackData.PUBLIC_KEY,
    email: userData.email,
    amount: checkAmount(paymentData.transaction_amount) * 100,
    ref: paymentData.transaction_id,
    metadata: {
      custom_fields: [
        {
          display_name: "Paid by",
          variable_name: "paid_by",
          value: `${userData.first_name} ${userData.last_name}`,
        },
        {
          display_name: "Category",
          variable_name: "category",
          value: `${paymentType}`,
        },
        {
          display_name: "Paying entity",
          variable_name: "paying_entity",
          value: "Youth COOP",
        },
      ],
    },
    //subaccount: "ACCT_edff0dmy8yri98o",
    callback: function (response) {
      if (response.status === "success") {
        if (paymentType === "Account Payment") {
          VerifyPayment(paymentData);
        } else if (paymentType === "Development Levy") {
          verifyDevelopmentPayment(response, paymentData, paystackData);
        } else if (paymentType === "Other Payments") {
          verifyOtherPayment(response, paymentData, paystackData);
        }
      } else {
        toast.error(
          "Your transaction could not be processed. Try again or contact support"
        );
      }
    },
  };
  let handler = PaystackPop.setup(obj);
  handler.openIframe();
}

// Call backend endpoint to verify account payments
const VerifyPayment = (data) => {
  try {
    axios
      .post(
        `https://youthapi.ogscofed.coop/payments/verify-payment/${data.transaction_id}/`,
        {
          coop_member: data.email,
          transaction_amount: data.amount,
          transaction_id: data.transaction_id,
        }
      )
      .then((res) => {
        window.location.reload(true);
        toast.success("Your payment has been made and verified");
      });
  } catch (error) {
    toast.error(
      "Your payment has been made but not verified. Contact support for help"
    );
  }
};

// Call paystack api to verify registration payment
const verifyRegistrationPayment = (paymentData, data, payStackData) => {
  try {
    axios
      .get(
        `https://api.paystack.co/transaction/verify/${paymentData.reference}`,
        {
          headers: {
            Authorization: `Bearer ${payStackData.SECRET_KEY}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        registrationComplete(res.data, data);
      });
  } catch (error) {}
};

// send report to api to change registration database entry to verify
const registrationComplete = async (paymentData, data) => {
  if (
    paymentData.data["amount"] ===
    checkAmount(data.transaction_amount) * 100
  ) {
    await axios
      .post(
        `https://youthapi.ogscofed.coop/payments/registration/verify-payment/${paymentData.data["reference"]}/`
      )
      .then((res) => {
        toast.success("Registration payment made and verified");
        window.location.href = "/account/user/dashboard";
      })
      .catch((error) => {
        toast.error(
          "Transaction made but could not verify payment. Contact support!"
        );
      });
  }
};

// Call paystack api to verify development levy
const verifyDevelopmentPayment = (paymentData, data, payStackData) => {
  try {
    axios
      .get(
        `https://api.paystack.co/transaction/verify/${paymentData.reference}`,
        {
          headers: {
            Authorization: `Bearer ${payStackData.SECRET_KEY}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        developmentComplete(res.data, data);
      });
  } catch (error) {}
};

// send report to api to change development database entry to verify
const developmentComplete = async (paymentData, data) => {
  if (
    paymentData.data["amount"] ===
    checkAmount(data.transaction_amount) * 100
  ) {
    await axios
      .post(
        `https://youthapi.ogscofed.coop/payments/development-levy/verify-payment/${paymentData.data["reference"]}/`
      )
      .then((res) => {
        toast.success("Development levy payment made and verified");
        window.location.href = "/account/user/dashboard";
        // window.location.reload(true);
      })
      .catch((error) => {
        toast.error(
          "Transaction made but could not verify payment. Contact support!"
        );
      });
  }
};

// Call paystack api to verify other payments
const verifyOtherPayment = (paymentData, data, payStackData) => {
  try {
    axios
      .get(
        `https://api.paystack.co/transaction/verify/${paymentData.reference}`,
        {
          headers: {
            Authorization: `Bearer ${payStackData.SECRET_KEY}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        otherPaymentComplete(res.data, data);
      });
  } catch (error) {}
};

// send report to api to change other payments database entry to verify
const otherPaymentComplete = async (paymentData, data) => {
  if (
    paymentData.data["amount"] ===
    checkAmount(data.transaction_amount) * 100
  ) {
    await axios
      .post(
        `https://youthapi.ogscofed.coop/payments/others/verify-payment/${paymentData.data["reference"]}/`
      )
      .then((res) => {
        toast.success(`${data.payment_purpose} payment made and verified`);
        window.location.href = "/account/user/dashboard";
      })
      .catch((error) => {
        toast.error(
          "Transaction made but could not verify payment. Contact support!"
        );
      });
  }
};

// Function to calculate total amount to be paid with charges
function checkAmount(amount) {
  if (amount < 2500) {
    amount = amount + (Math.trunc(amount * 0.0155));
  } else if (amount === 2500) {
    amount = 2499 + 37.5;
  } else {
    const int = Math.trunc(amount * 0.0155 + 100);
    if (int > 2000) {
      amount = amount + 2000;
    } else {
      amount = amount + int;
    }
  }

  return amount;
}

export { payWithPayStack, payWithPayStackReg };
