import { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Navbar,
  Button,
  Link,
  Text,
  Image,
  Dropdown,
  Spacer,
  User,
} from "@nextui-org/react";
import axios from "axios";
import { FaSortDown } from "react-icons/fa";

import AuthContext from "../../context/AuthContext";
import logo from "../../static/images/logo.png";

const NavbarComp = () => {
  const matches = useMediaQuery("(max-width:650px)");
  const { user, logoutUser } = useContext(AuthContext);
  const loc = useLocation();
  const act = "#ffffff";

  const [userPicture, setUserPicture] = useState(null);
  const [pictureStatus, setPictureStatus] = useState(false);

  const getPicture = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/${user.user_id}/profile-img/`)
        .then((res) => {
          setUserPicture(res.data);
          setPictureStatus(true);
        });
    } catch (error) {
      setPictureStatus(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (!pictureStatus) {
        getPicture();
      }
    }
  });

  return (
    <Navbar variant={"static"} disableShadow shouldHideOnScroll>
      <Navbar.Brand>
        <Navbar.Toggle showIn={"sm"} aria-label="toggle navigation" />
        <div style={{ width: "8em" }}>
          <Image src={logo} height={"75%"} width={"75%"} />
        </div>
        <Text b color="inherit" hideIn="xs">
          YOUTH COOPERATIVE SOCIETY
        </Text>
      </Navbar.Brand>
      <Navbar.Content hideIn="sm" variant="underline-rounded" activeColor={act}>
        <Navbar.Link href="/about">About Us</Navbar.Link>
        <Dropdown isBordered>
          <Navbar.Item>
            <Dropdown.Button
              auto
              light
              css={{
                px: 0,
                dflex: "center",
                svg: { pe: "none" },
              }}
              ripple={true}
            >
              Cooperative
            </Dropdown.Button>
          </Navbar.Item>
          <Dropdown.Menu
            aria-label="ogscofed"
            css={{
              $$dropdownMenuWidth: "340px",
              $$dropdownItemHeight: "40px",
              "& .nextui-dropdown-item": {
                py: "$4",
                // dropdown item left icon
                svg: {
                  color: "$secondary",
                  mr: "$10",
                },
                // dropdown item title
                "& .nextui-dropdown-item-content": {
                  w: "100%",
                  fontWeight: "$semibold",
                },
              },
            }}
          >
            <Dropdown.Item key="meetings" showFullDescription>
              <Navbar.Link href="#">Meetings</Navbar.Link>
            </Dropdown.Item>
            <Dropdown.Item key="events" showFullDescription>
              <Navbar.Link href="#">Events</Navbar.Link>
            </Dropdown.Item>
            <Dropdown.Item key="byelaw" showFullDescription>
              <Navbar.Link href="/docs/byeLaw.pdf" target="_blank" download>
                Bye laws
              </Navbar.Link>
            </Dropdown.Item>
            <Dropdown.Item key="terms" showFullDescription>
              <Navbar.Link href="/docs/terms.pdf" target="_blank" download>
                Terms and Conditions
              </Navbar.Link>
            </Dropdown.Item>
            <Dropdown.Item key="bod" showFullDescription>
              <Navbar.Link href="/directors">Board of directors</Navbar.Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Content>
      {user ? (
        <Navbar.Content
          hideIn={"xs"}
          variant="underline-rounded"
          activeColor={act}
        >
          <Navbar.Link color="inherit" href="/" isActive={loc.pathname === "/"}>
            Home
          </Navbar.Link>
          {/* <Navbar.Link
            color="inherit"
            href="/account/user/dashboard"
            isActive={loc.pathname === "/account/user/dashboard"}
          >
            Dashboard
          </Navbar.Link>
          <Navbar.Item>
            <Button auto flat color={"error"} onClick={logoutUser}>
              Sign out
            </Button>
          </Navbar.Item> */}
          {/* {pictureStatus ? ( */}
            <Dropdown>
              <Navbar.Item>
                <Dropdown.Trigger
                  css={{ background: "transparent", color: "$primary" }}
                >
                  <div style={{display: "flex", alignItems: "center"}}>
                    {pictureStatus ? (<User
                      src={`https://youthapi.ogscofed.coop/${userPicture.img_file}`}
                      css={{paddingRight: 0}}
                    />) : (
                      <User
                      css={{paddingRight: 0}}
                    />
                    )}
                    
                    <FaSortDown color="inherit" height={"0.8em"} />
                  </div>
                </Dropdown.Trigger>
              </Navbar.Item>
              <Dropdown.Menu
                aria-label="ogscofed"
                css={{
                  $$dropdownMenuWidth: "340px",
                  $$dropdownItemHeight: "40px",
                  "& .nextui-dropdown-item": {
                    py: "$4",
                    // dropdown item left icon
                    svg: {
                      color: "$secondary",
                      mr: "$10",
                    },
                    // dropdown item title
                    "& .nextui-dropdown-item-content": {
                      w: "100%",
                      fontWeight: "$semibold",
                    },
                  },
                }}
              >
                <Dropdown.Item>
                  <Navbar.Link
                    color="inherit"
                    href="/account/user/dashboard"
                    isActive={loc.pathname === "/account/user/dashboard"}
                  >
                    Dashboard
                  </Navbar.Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button light color={"error"} onClick={logoutUser} css={{justifyContent: "left", paddingLeft: 0}}>
                    Sign out
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          {/* ) : (
            <User />
          )} */}
        </Navbar.Content>
      ) : (
        <Navbar.Content hideIn={"xs"} variant="underline-rounded">
          <Navbar.Link color="inherit" href="/" isActive={loc.pathname === "/"}>
            Home
          </Navbar.Link>
          <Navbar.Link
            color="inherit"
            href="/account/login"
            isActive={loc.pathname === "/account/login"}
          >
            Login
          </Navbar.Link>
          <Navbar.Item>
            <Button auto flat as={Link} href="/account/register">
              Sign Up
            </Button>
          </Navbar.Item>
        </Navbar.Content>
      )}

      <Navbar.Collapse>
        {matches ? (
          user ? (
            <>
              <Navbar.CollapseItem>
                <Link
                  color="inherit"
                  css={{
                    minWidth: "100%",
                  }}
                  href="/"
                >
                  Home
                </Link>
              </Navbar.CollapseItem>

              <Navbar.CollapseItem>
                <Link
                  color="inherit"
                  css={{
                    minWidth: "100%",
                  }}
                  href="/account/user/dashboard"
                >
                  Dashboard
                </Link>
              </Navbar.CollapseItem>
            </>
          ) : (
            <Navbar.CollapseItem>
              <Link
                color="inherit"
                css={{
                  minWidth: "100%",
                }}
                href="/"
              >
                Home
              </Link>
            </Navbar.CollapseItem>
          )
        ) : null}
        <Navbar.CollapseItem>
          <Link href="/about" color="inherit">
            About Us
          </Link>
        </Navbar.CollapseItem>
        <Dropdown isBordered>
          <Navbar.CollapseItem>
            <Dropdown.Button
              auto
              light
              css={{
                px: 0,
                dflex: "center",
                svg: { pe: "none" },
                fontSize: "1.25rem",
              }}
              ripple={true}
            >
              Cooperative
            </Dropdown.Button>
          </Navbar.CollapseItem>
          <Dropdown.Menu
            aria-label="ogscofed"
            css={{
              $$dropdownMenuWidth: "340px",
              $$dropdownItemHeight: "40px",
              "& .nextui-dropdown-item": {
                py: "$4",
                // dropdown item left icon
                svg: {
                  color: "$secondary",
                  mr: "$10",
                },
                // dropdown item title
                "& .nextui-dropdown-item-content": {
                  w: "100%",
                  fontWeight: "$semibold",
                },
              },
            }}
          >
            <Dropdown.Item key="meetings" showFullDescription>
              <Link href="#" color="inherit">
                Meetings
              </Link>
            </Dropdown.Item>
            <Dropdown.Item key="events" showFullDescription>
              <Link href="#" color="inherit">
                Events
              </Link>
            </Dropdown.Item>
            <Dropdown.Item key="byelaw" showFullDescription>
              <Link
                href="/docs/byeLaw.pdf"
                target="_blank"
                download
                color="inherit"
              >
                Bye laws
              </Link>
            </Dropdown.Item>
            <Dropdown.Item key="terms" showFullDescription>
              <Link
                href="/docs/terms.pdf"
                target="_blank"
                download
                color="inherit"
              >
                Terms and Conditions
              </Link>
            </Dropdown.Item>
            <Dropdown.Item key="terms" showFullDescription>
              <Link href="/directors" color="inherit">
                Board of directors
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {matches ? (
          user ? (
            <>
              <Spacer y={1} />
              <Navbar.CollapseItem>
                <Link onClick={logoutUser} color="error">
                  Log out
                </Link>
              </Navbar.CollapseItem>
            </>
          ) : (
            <>
              <Spacer y={1} />
              <Navbar.CollapseItem>
                <Link
                  color="inherit"
                  css={{
                    minWidth: "100%",
                  }}
                  href="/account/login/"
                  isActive={loc.pathname === "/account/login"}
                >
                  Login
                </Link>
              </Navbar.CollapseItem>

              <Navbar.CollapseItem>
                <Link
                  color="primary"
                  href="/account/register"
                  isActive={loc.pathname === "/account/register"}
                >
                  Register
                </Link>
              </Navbar.CollapseItem>
            </>
          )
        ) : null}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComp;
