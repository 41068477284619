import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import Scrollbar from 'perfect-scrollbar-react';
import { toast } from "react-toastify";

import { Modal, Text, Link, Button, Loading } from "@nextui-org/react";
import { MdVisibility, MdVisibilityOff, MdCircle } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { TextField, Grid, Box, Typography } from "@mui/material";

import PerfectScrollbar from 'perfect-scrollbar';

import Footer from "../../../Components/Navs/footer";
import NavbarComp from "../../../Components/Navs/topNav";

import side from "../../../static/images/side.jpg";


function ResetPassword() {
  const history = useHistory();
  const { uidb64, token } = useParams();

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  
  const [passwordError, setPasswordError] = useState("");
  const [password2Error, setPassword2Error] = useState("");

  const [loading, setLoading] = useState(false);
  
  const onChangeClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPasswordError("");
    setPassword2Error("");

    const userData = {
      password: password,
      uidb64: uidb64,
      token: token
    };

    if (password === password2) {
        try {
        await axios.patch(`https://youthapi.ogscofed.coop/account/password-reset-complete`, userData)
        .then((res) => {
            history.push(`/account/login`);
            toast.success(res.data["message"].toString());
        })
        } catch (error) {
            toast.error("You have error in your form");
            if (error.response.data.hasOwnProperty("detail")) {
                toast.error(error.response.data["detail"].toString());
            }
            if (error.response.data.hasOwnProperty("message")) {
                toast.error(error.response.data["message"].toString());
            }
            if (error.response.data.hasOwnProperty("password")) {
                setPasswordError(error.response.data["password"].toString())
            }
            if (error.response.data.hasOwnProperty("uidb64")) {
                toast.error(error.response.data["uidb64"].toString());
            }
            if (error.response.data.hasOwnProperty("token")) {
                toast.error(error.response.data["token"].toString());
            }
            toast.error("If problem persists, request a new password reset.")
        }
    } else {
        setPasswordError("Passwords must be the same");
        setPassword2Error("Passwords must be the same");
    }
    
    setLoading(false);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const form = (
    <Grid container spacing={0} className="">
      <Grid item xs={0} sm={5} className="w-100 side-container">
        <Box className="w-100 vh-100 d-flex flex-column">
          <img src={side} className="img-cover" width="100%" height="100%" alt="Signup vector"/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} mt={10} pl={15} pr={10} className="position-relative">
        <Typography className="mt-3">
          Don't have an account? <Link href="/account/register" color={'secondary'}>Register</Link>&nbsp;here
        </Typography>
        <Typography className="mt-1">
          Did not request for password reset? Just <Link href="/account/login" color={'secondary'}>Login</Link>&nbsp;here
        </Typography>
        <Typography component={"h1"} mt={10} fontWeight={'700'} fontSize={"3rem"} lineHeight={"100%"}>
          Password reset, <br/>Enter <Typography fontWeight={'700'} fontSize={"3rem"} component={"span"} color="primary">a</Typography> new password
          <Typography component={"span"} color="primary" size={'0.5em'} css={{ marginLeft:'0.15em', marginTop:'0.1em' }}>
            <MdCircle />
          </Typography>
        </Typography>
        <Box component={"form"} noValidate autoComplete="off" onSubmit={onChangeClick}>
          <Grid container mt={7} spacing={2}>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth 
                      variant="standard" 
                      error={!!passwordError}
                      required 
                      autoComplete="new-password" >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                          id="password"
                          type={showPassword ? 'text' : 'password'}
                          onChange = {e => setPassword(e.target.value)}
                          inputProps={{
                            autoComplete: 'new-password'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText>{!!passwordError ? 
                          (passwordError) : ('')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
              <FormControl fullWidth 
                      variant="standard" 
                      error={!!password2Error}
                      required 
                      autoComplete="new-password" >
                        <InputLabel htmlFor="password2">Password confirmation</InputLabel>
                        <Input
                          id="password2"
                          type={showPassword2 ? 'text' : 'password'}
                          onChange = {e => setPassword2(e.target.value)}
                          inputProps={{
                            autoComplete: 'new-password'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword2}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword2 ? <MdVisibilityOff /> : <MdVisibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText>{!!password2Error ? 
                          (password2Error) : ('')}</FormHelperText>
                </FormControl>
              </Grid>
          </Grid>
          <Grid container mt={5} spacing={2}>
            <Grid item xs={12}>
            <Button
                      color="primary"
                      // fullWidth
                      type='submit'
                      disabled={ loading }
                      
                    >
                      {loading ? (
                        <Loading textColor={"primary"} color="currentColor" type="points" size="sm" />
                      ) : (
                        'Change password'
                      )}
            </Button>
            </Grid>
          </Grid>
        </Box>
          <Footer />
      </Grid>
    </Grid>
  );
    
  return (
    <main className="">
        <NavbarComp />
        <Box>
          {form}
        </Box>
    </main>
  );
}


export default ResetPassword;
