import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Scrollbar from 'perfect-scrollbar-react';
import { toast } from "react-toastify";

import AuthContext from "../../../context/AuthContext";
import useAxios from "../../../utils/useAxios";

import { Link, Button, Loading } from "@nextui-org/react";
import { MdVisibility, MdVisibilityOff, MdCircle } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { TextField, Grid, Box, Typography } from "@mui/material";

import PerfectScrollbar from 'perfect-scrollbar';

import Footer from "../../../Components/Navs/footer";
import NavbarComp from "../../../Components/Navs/topNav";

import side from "../../../static/images/side.jpg";


const ChangePassword = () => {
  const history = useHistory();
  const { logoutUser } = useContext(AuthContext);

  const api = useAxios();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPassword2Error, setNewPassword2Error] = useState("");

  const [loading, setLoading] = useState(false);
  
  const onChangeClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setOldPasswordError("");
    setNewPasswordError("");
    setNewPassword2Error("");

    const userData = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    if (newPassword === newPassword2) {
        try {
        await api.put(`https://youthapi.ogscofed.coop/account/change-password`, 
            userData)
        .then((res) => {
            logoutUser();
            history.push(`/account/login`);
            toast.success(res.data["message"].toString());
        })
        } catch (error) {
            toast.error("You have error in your form");
            if (error.response.data.hasOwnProperty("detail")) {
                toast.error(error.response.data["detail"].toString());
            }
            if (error.response.data.hasOwnProperty("old_password")) {
                setOldPasswordError(error.response.data["old_password"].toString())
            }
            if (error.response.data.hasOwnProperty("new_password")) {
                setNewPasswordError(error.response.data["new_password"].toString())
            }
        }
    } else {
        setNewPasswordError("Passwords must be the same");
        setNewPassword2Error("Passwords must be the same");
    }
    
    setLoading(false);
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowNewPassword2 = () => setShowNewPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const form = (
    <Grid container spacing={0} className="">
      <Grid item xs={0} sm={5} className="w-100 side-container">
        <Box className="w-100 vh-100 d-flex flex-column">
          <img src={side} className="img-cover" width="100%" height="100%" alt="Signup vector"/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} mt={10} pl={15} pr={10} className="position-relative">
        {/* <Typography className="mt-3">
          Don't have an account? <Link href="/account/register" color={'secondary'}>Register</Link>&nbsp;here
        </Typography> */}
        <Typography className="mt-1">
          Did not request for password change? Go to <Link href="/account/user/dashboard" color={'secondary'}>dashboard</Link>
        </Typography>
        <Typography component={"h1"} mt={10} fontWeight={'700'} fontSize={"3rem"} lineHeight={"100%"}>
          Password change, <br/>Enter <Typography fontWeight={'700'} fontSize={"3rem"} component={"span"} color="primary">a</Typography> new password
          <Typography component={"span"} color="primary" size={'0.5em'} css={{ marginLeft:'0.15em', marginTop:'0.1em' }}>
            <MdCircle />
          </Typography>
        </Typography>
        <Box component={"form"} noValidate autoComplete="off" onSubmit={onChangeClick}>
          <Grid container mt={7} spacing={2}>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth 
                      variant="standard" 
                      error={!!oldPasswordError}
                      required 
                      autoComplete="new-password" >
                        <InputLabel htmlFor="old-password">Old Password</InputLabel>
                        <Input
                          id="old-password"
                          type={showOldPassword ? 'text' : 'password'}
                          onChange = {e => setOldPassword(e.target.value)}
                          inputProps={{
                            autoComplete: 'new-password'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showOldPassword ? <MdVisibilityOff /> : <MdVisibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText>{!!oldPasswordError ? 
                          (oldPasswordError) : ('')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
              <FormControl fullWidth 
                      variant="standard" 
                      error={!!newPasswordError}
                      required 
                      autoComplete="new-password" >
                        <InputLabel htmlFor="new-password">New Password</InputLabel>
                        <Input
                          id="new-password"
                          type={showNewPassword ? 'text' : 'password'}
                          onChange = {e => setNewPassword(e.target.value)}
                          inputProps={{
                            autoComplete: 'new-password'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showNewPassword ? <MdVisibilityOff /> : <MdVisibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText>{!!newPasswordError ? 
                          (newPasswordError) : ('')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
              <FormControl fullWidth 
                      variant="standard" 
                      error={!!newPassword2Error}
                      required 
                      autoComplete="new-password" >
                        <InputLabel htmlFor="new-password2">New Password confirmation</InputLabel>
                        <Input
                          id="new-password2"
                          type={showNewPassword2 ? 'text' : 'password'}
                          onChange = {e => setNewPassword2(e.target.value)}
                          inputProps={{
                            autoComplete: 'new-password'
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword2}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showNewPassword2 ? <MdVisibilityOff /> : <MdVisibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText>{!!newPassword2Error ? 
                          (newPassword2Error) : ('')}</FormHelperText>
                </FormControl>
              </Grid>
          </Grid>
          <Grid container mt={5} spacing={2}>
            <Grid item xs={12}>
            <Button
                      color="primary"
                      // fullWidth
                      type='submit'
                      disabled={ loading }
                      
                    >
                      {loading ? (
                        <Loading textColor={"primary"} color="currentColor" type="points" size="sm" />
                      ) : (
                        'Change password'
                      )}
            </Button>
            </Grid>
          </Grid>
        </Box>        
        <Footer />
      </Grid>
    </Grid>
  );
    
  return (
    <main className="">
        <NavbarComp />
        <Box>
          {form}
        </Box>
    </main>
  );
}

export default ChangePassword;