import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import PerfectScrollbar from "perfect-scrollbar";

import AuthContext from "../../context/AuthContext";
import {
  SideNav,
  TopNav,
  DashboardFooter,
} from "../../Components/Navs/dashboard";

import { Loading, Avatar, Modal } from "@nextui-org/react";
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  Badge,
  IconButton,
  TextField,
  Alert,
  Collapse,
} from "@mui/material";
import { FaPiggyBank, FaUser, FaUserEdit } from "react-icons/fa";
import { MdClose } from "react-icons/md";
// import CloseIcon from '@mui/icons-material/Close';

import side from "../../static/images/side.jpg";
import { MdLocalPolice, MdPassword, MdVerifiedUser } from "react-icons/md";
import { useHistory } from "react-router-dom";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      maxLength={10}
      minLength={10}
      allowLeadingZeros
      allowNegative
      datatype="integer"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Profile = () => {
  const history = useHistory();
  const { user, logoutUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({});
  const [referees, setReferees] = useState({});

  const [profilePicture, setProfilePicture] = useState(null);

  const [userPicture, setUserPicture] = useState(null);

  const [regStatus, setRegStatus] = useState(false);
  const [refereeStatus, setRefereeStatus] = useState(false);
  const [levyStatus, setLevyStatus] = useState(false);
  const [levyYears, setLevyYears] = useState([]);

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNunmber] = useState();
  const [bankName, setBankName] = useState("");

  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNunmberError] = useState(0);
  const [bankNameError, setBankNameError] = useState("");

  const [status, setStatus] = useState(false);
  const [pictureStatus, setPictureStatus] = useState(false);
  const [bankDetailsStatus, setBankDetailsStatus] = useState(false);

  const [visible, setVisible] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);

  const [uploading, isUploading] = useState(false);
  
  const [open, setOpen] = React.useState(true);

  const closeHandler = () => {
    setVisible(false);
    setVisible2(false);
  };

  function openProfilePictureDialog() {
    document.getElementById("profilePictureInput").click();
  }

  const uploadPicture = async () => {
    isUploading(true);
    let form_data = new FormData();

    if (profilePicture) {
      form_data.append(
        "img_file",
        profilePicture,
        `${user.user_id}_${userData.last_name}.${profilePicture.name
          .split(".")
          .pop()}`
      );
    }

    if (user) {
      form_data.append("account", user.user_id);
    }

    try {
      await axios
        .post(
          `https://youthapi.ogscofed.coop/account/${user.user_id}/profile-img/`,
          form_data
        )
        .then((res) => {
          window.location.reload(true);
        });
    } catch (error) {
      if (error.response.data.hasOwnProperty("img_file")) {
        toast.error(`${error.response.data["img_file"].toString()}`);
      } else {
        toast.error(
          "Unable to upload picture. \nContact support if problem persists."
        );
      }
    }

    isUploading(false);
  };

  const saveBank = async () => {
    isUploading(true);

    const userBank = {
      account_name: accountName,
      account_number: accountNumber,
      bank_name: bankName,
    };

    try {
      await axios
        .post(
          `https://youthapi.ogscofed.coop/account/${user.user_id}/bank-details/`,
          userBank
        )
        .then((res) => {
          toast.success("Bank details added successfully");
          window.location.reload(true);
        });
    } catch (error) {
      if (error.response.data.hasOwnProperty("detail")) {
        toast.error(`${error.response.data["detail"].toString()}`);
      }
      if (error.response.data.hasOwnProperty("bank_name")) {
        setBankNameError(`${error.response.data["bank_name"].toString()}`);
      }
      if (error.response.data.hasOwnProperty("account_name")) {
        setAccountNameError(
          `${error.response.data["account_name"].toString()}`
        );
      }
      if (error.response.data.hasOwnProperty("account_number")) {
        setAccountNunmberError(
          `${error.response.data["account_number"].toString()}`
        );
      }
    }

    isUploading(false);
  };

  const getPicture = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/${user.user_id}/profile-img/`)
        .then((res) => {
          setUserPicture(res.data);
          setPictureStatus(true);
        });
    } catch (error) {
      setPictureStatus(false);
    }
  };

  const getBankDetails = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/${user.user_id}/bank-details/`)
        .then((res) => {
          setBankDetailsStatus(true);
        });
    } catch (error) {
      setBankDetailsStatus(false);
    }
  };

  const getData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/dashboard/${user.user_id}/`)
        .then((res) => {
          setUserData(res.data);
          setStatus(true);
        });
    } catch (error) {
      toast.error("Unable to get User, Refresh or Try again!");
      logoutUser();
    }
  };

  const getReferees = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/${user.user_id}/referee/`)
        .then((res) => {
          setReferees(res.data);
          setRefereeStatus(true);
        });
    } catch (error) {
      setRefereeStatus(false);
      // toast.error("Unable to get Referees, refresh or try again!");
    }
  };

  // Function to check if user has paid registration fees
  const getRegistrationData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/payments/registration/${user.user_id}/`)
        .then((res) => {
          setRegStatus(true);
          // toast.info(res.data["message"].toString());
        });
    } catch (error) {
      setRegStatus(false);
      toast.info(error.response.data["error"].toString());
    }
  };

  // Function to check if user has paid development levy
  const getDevelopmentData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/payments/development-levy/${user.user_id}/`)
        .then((res) => {
          var arr = Object.keys(res.data).map(function (key) {
            return res.data[key]["for_year"];
          });
          setLevyYears(arr.reverse());
          setLevyStatus(true);
        });
    } catch (error) {
      setLevyStatus(false);
      toast.info(error.response.data["error"].toString());
    }
  };

  useEffect(() => {
    if (document.getElementsByClassName("main-content")[0]) {
      new PerfectScrollbar(".main-content");
    }

    if (document.getElementsByClassName("navbar-collapse")[0]) {
      new PerfectScrollbar(".navbar:not(.navbar-expand-lg) .navbar-collapse");
    }

    if (!status) {
      getData();
    }
    if (!refereeStatus) {
      getReferees();
    }
    if (!regStatus) {
      getRegistrationData();
    }
    if (!levyStatus) {
      getDevelopmentData();
    }
    if (!pictureStatus) {
      getPicture();
    }
    if (!bankDetailsStatus) {
      getBankDetails();
    }
  }, []);

  return (
    <>
      {user ? (
        status ? (
          <div>
            <SideNav active="profile" />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
              <TopNav />
              {refereeStatus ? (
                <></>
              ) : (
                <div
                  class="alert alert-info shadow-5 alert-dismissible text-white m-3 text-center d-flex align-items-center"
                  role="alert"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-label="Warning:"
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  <span className="text-sm">
                    You do not have your referees set, usage might be reduced.
                    Please head over to the{" "}
                    <a
                      href="/account/user/dashboard/referees"
                      className="alert-link text-white"
                    >
                      REFEREES
                    </a>{" "}
                    section to add them.
                  </span>
                  <button
                    type="button"
                    className="btn-close text-lg py-3 opacity-10"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              {pictureStatus ? (
                <></>
              ) : (
                <div
                  class="alert alert-info shadow-5 alert-dismissible text-white m-3 text-center d-flex align-items-center"
                  role="alert"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-label="Warning:"
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  <span className="text-sm">
                    You have not uploaded your profile image, usage might be
                    reduced. Please head over to the{" "}
                    <a
                      href="/account/user/dashboard/profile"
                      className="alert-link text-white"
                    >
                      PROFILE
                    </a>{" "}
                    section to upload it.
                  </span>
                  <button
                    type="button"
                    className="btn-close text-lg py-3 opacity-10"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              <div className="container-fluid px-2 px-md-4">
                <div
                  className="page-header min-height-300 border-radius-xl mt-4"
                  style={{ backgroundImage: `url('${side}')` }}
                >
                  <span className="mask bg-gradient-primary opacity-7"></span>
                </div>
                <div className="card card-body mx-3 mx-md-4 mt-n6 mb-3">
                  <Grid
                    container
                    className="flex-column align-content-center"
                    spacing={2}
                  >
                    <Grid item xs={12} className="d-flex align-self-center">
                      {pictureStatus ? (
                        <Box
                          sx={{
                            width: "8rem",
                            height: "8rem",
                            backgroundImage: `url(${`https://youthapi.ogscofed.coop/${userPicture.img_file}`})`,
                            backgroundColor: "#fff",
                            backgroundSize: "8rem",
                          }}
                          className="shadow-primary mt-n6 rounded-3"
                        >
                          {/* <Image
                            src={``}
                            alt={`${user.user_id}_${userData.last_name}`}
                            width={"100%"}
                            height={"auto"}
                            objectFit="cover"
                          /> */}
                        </Box>
                      ) : (
                        <Avatar
                          squared
                          bordered
                          color="primary"
                          css={{ width: "6rem", height: "6rem" }}
                          className="shadow-primary mt-n6 rounded-3"
                          icon={<FaUser color="#fff" size={"100%"} />}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} className="">
                      <Typography
                        component={"h1"}
                        fontSize={"2rem"}
                        fontWeight={700}
                        className="text-center"
                      >
                        {userData.first_name} {userData.middle_name}{" "}
                        {userData.last_name}
                        {regStatus ? (
                          <Tooltip
                            title="Registration payment made and verified"
                            placement="top"
                          >
                            <Badge color="secondary" variant="dot">
                              <IconButton className="p-0 ms-3">
                                <MdVerifiedUser
                                  size={"1.5rem"}
                                  className="text-primary"
                                />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Registration payment not made or verified"
                            placement="top"
                          >
                            <Badge variant="dot">
                              <IconButton className="p-0 ms-3" disabled>
                                <MdVerifiedUser
                                  size={"1.5rem"}
                                  className="text-muted"
                                />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                        )}
                        {levyStatus ? (
                          <Tooltip
                            title={`Development levy made for ${levyYears.join(
                              ", "
                            )}`}
                            placement="top"
                          >
                            <Badge
                              color="secondary"
                              badgeContent={levyYears.length}
                            >
                              <IconButton className="p-0 ms-1">
                                <MdLocalPolice
                                  size={"1.5rem"}
                                  className="text-primary"
                                />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={`Development levy payment not made for the year`}
                            placement="top"
                          >
                            <Badge variant="dot">
                              <IconButton className="p-0 ms-1" disabled>
                                <MdLocalPolice
                                  size={"1.5rem"}
                                  className="text-muted"
                                />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className="align-content-center"
                    m={5}
                    spacing={2}
                    sx={{ m: { xs: 1, md: 5 } }}
                  >
                    <Grid
                      item
                      xs={0}
                      md={2}
                      sx={{ display: { xs: "none", md: "block" } }}
                    ></Grid>
                    <Grid item xs={12} sm={7} md={5}>
                      <h6 className="">Profile Information</h6>
                      <Box className="d-flex">
                        <ul className="list-group ms-0">
                          <li className="list-group-item border-0 ps-0 pt-0 text-sm mb-0">
                            <strong className="text-dark">Full Name:</strong>{" "}
                            &nbsp; {userData.first_name} {userData.middle_name}{" "}
                            {userData.last_name}
                          </li>
                          <li className="list-group-item border-0 ps-0 text-sm mb-0">
                            <strong className="text-dark">
                              Membership ID:
                            </strong>{" "}
                            &nbsp;{userData.id}
                          </li>
                          <li className="list-group-item border-0 ps-0 text-sm mb-0">
                            <strong className="text-dark">Mobile:</strong>{" "}
                            &nbsp;{userData.telephone}
                          </li>
                          <li className="list-group-item border-0 ps-0 text-sm mb-0">
                            <strong className="text-dark">Email:</strong> &nbsp;{" "}
                            {userData.email}
                          </li>
                          <li className="list-group-item border-0 ps-0 text-sm mb-0">
                            <strong className="text-dark">Location:</strong>{" "}
                            &nbsp;{userData.city}, {userData.states}
                          </li>
                          <li className="list-group-item border-0 ps-0 pb-0 mb-0">
                            <strong className="text-dark text-sm">
                              Social:
                            </strong>{" "}
                            &nbsp;
                            <button className="btn btn-facebook btn-simple mb-0 ps-1 pe-2 py-0">
                              <i className="fab fa-facebook fa-lg"></i>
                            </button>
                            <button className="btn btn-twitter btn-simple mb-0 ps-1 pe-2 py-0">
                              <i className="fab fa-twitter fa-lg"></i>
                            </button>
                            <button className="btn btn-instagram btn-simple mb-0 ps-1 pe-2 py-0">
                              <i className="fab fa-instagram fa-lg"></i>
                            </button>
                          </li>
                        </ul>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!regStatus}
                        endIcon={<FaUserEdit />}
                      >
                        Edit Profile
                      </Button>
                      <br />
                      <Button
                        className="mt-2"
                        variant="contained"
                        color="primary"
                        disabled={!regStatus}
                        endIcon={<FaUserEdit />}
                        onClick={(e) => setVisible(true)}
                      >
                        Change Picture
                      </Button>
                      <br />
                      {/* {bankDetailsStatus ? } */}
                      <Button
                        className="mt-2"
                        variant="contained"
                        color="primary"
                        disabled={bankDetailsStatus}
                        endIcon={<FaPiggyBank />}
                        onClick={(e) => { setOpen(true); setVisible2(true)}}
                      >
                        Add bank details
                      </Button>
                      <br />
                      <Button
                        className="mt-2"
                        variant="outlined"
                        color="primary"
                        onClick={(e) =>
                          history.push("/account/password-change")
                        }
                        endIcon={<MdPassword />}
                      >
                        Change Password
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={0}
                      md={2}
                      sx={{ display: { xs: "none", md: "block" } }}
                    ></Grid>
                  </Grid>
                </div>
              </div>

              <Modal
                closeButton
                aria-labelledby="Profile-picture-modal"
                open={visible}
                onClose={closeHandler}
              >
                <Modal.Header>
                  <Typography variant="h6">Add Profile Picture</Typography>
                </Modal.Header>
                <Modal.Body>
                  <Button
                    onClick={openProfilePictureDialog}
                    id={"profilePictureButton"}
                    htmlFor={"profilePictureInput"}
                  >
                    {profilePicture ? profilePicture.name : "Choose picture"}
                  </Button>
                  <input
                    onChange={(e) => {
                      setProfilePicture(e.target.files[0]);
                      profilePicture.name = `${user.user_id}_${user.last_name}`;
                    }}
                    name="profilePicture"
                    hidden
                    id={"profilePictureInput"}
                    type="file"
                    accept="image/*"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button auto flat color="error" onClick={closeHandler}>
                    Close
                  </Button>
                  <Button auto onClick={uploadPicture} disabled={uploading}>
                    Upload
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Modal to add bank details */}
              <Modal
                closeButton
                aria-labelledby="Bank-details-modal"
                open={visible2}
                onClose={closeHandler}
                preventClose
              >
                <Modal.Header>
                  <Typography variant="h6">Add Bank Details</Typography>
                </Modal.Header>
                <Modal.Body>                  
                  <TextField
                    label="Bank name"
                    fullWidth
                    required
                    clearable
                    variant="standard"
                    onChange={(e) => {
                      setBankName(e.target.value);
                    }}
                    name="bank name"
                    value={bankName}
                    error={!!bankNameError}
                    helperText={!!bankNameError ? bankNameError : ""}
                  />
                  <TextField
                    label="Account name"
                    fullWidth
                    required
                    clearable
                    variant="standard"
                    onChange={(e) => {
                      setAccountName(e.target.value);
                    }}
                    name="bank name"
                    value={accountName}
                    error={!!accountNameError}
                    helperText={!!accountNameError ? accountNameError : ""}
                  />
                  <TextField
                    label="Account number"
                    fullWidth
                    required
                    clearable
                    variant="standard"
                    onChange={(e) => {
                      setAccountNunmber(e.target.value);
                    }}
                    name="account number"
                    value={accountNumber}
                    error={!!accountNumberError}
                    helperText={!!accountNumberError ? accountNumberError : ""}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                  <Collapse in={open}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <MdClose fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2, mt: 2 }}
                      severity="info"
                    >
                      Confirm all details before submitting
                    </Alert>
                  </Collapse>
                </Modal.Body>
                <Modal.Footer>
                  <Button auto flat color="error" onClick={closeHandler}>
                    Close
                  </Button>
                  <Button auto onClick={saveBank} disabled={uploading}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
              <DashboardFooter />
            </main>
          </div>
        ) : (
          <div>
            <div className="d-flex flex-column vw-100 vh-100 align-items-center justify-content-center">
              <Loading
                type="spinner"
                loadingCss={{ $$loadingSize: "200px", $$loadingBorder: "10px" }}
              />
            </div>
            <DashboardFooter />
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default Profile;
