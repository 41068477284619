import $ from "jquery";

export default function isInViewport(el) {
    var elementTop = $(el).offset().top;
    var elementBottom = elementTop + $(el).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom
}

// export default function checkViewPort(id) {
//   const box = document.querySelector(id);

//   document.addEventListener(
//     "scroll",
//     function () {
//       const messageText = isInViewport(box)
//         ? "The box is visible in the viewport"
//         : "The box is not visible in the viewport";

//       console.log(messageText);
//     },
//     {
//       passive: true,
//     }
//   );
// }
