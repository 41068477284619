import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import useAxios from "../../utils/useAxios.js"
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import PerfectScrollbar from "perfect-scrollbar";

import { Modal, Text, Button, Loading, Radio } from "@nextui-org/react";
import {
  TextField,
  Typography,
  Box,
  MenuItem,
  Collapse,
  Alert,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";

import AuthContext from "../../context/AuthContext";
import { payWithPayStack, payWithPayStackReg } from "../payments";
import logo from "../../static/images/logo.png";
import { MdClose } from "react-icons/md";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="&#8358;"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SideNav = (active) => {
  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    if (document.getElementsByClassName("main-content")[0]) {
      new PerfectScrollbar(".main-content");
    }

    if (document.getElementsByClassName("navbar-collapse")[0]) {
      new PerfectScrollbar(".navbar:not(.navbar-expand-lg) .navbar-collapse");
    }

    const iconNavbarSidenav = document.getElementById("iconNavbarSidenav");

    const iconSidenav = document.getElementById("iconSidenav");
    const sidenav = document.getElementById("sidenav-main");
    let body = document.getElementsByTagName("body")[0];
    let className = "g-sidenav-pinned";

    if (iconNavbarSidenav) {
      iconNavbarSidenav.addEventListener("click", toggleSidenav);
    }

    if (iconSidenav) {
      iconSidenav.addEventListener("click", toggleSidenav);
    }

    var ripples = document.querySelectorAll(".btn");

    for (var i = 0; i < ripples.length; i++) {
      ripples[i].addEventListener(
        "click",
        function (e) {
          var targetEl = e.target;
          var rippleDiv = targetEl.querySelector(".ripple");

          rippleDiv = document.createElement("span");
          rippleDiv.classList.add("ripple");
          rippleDiv.style.width = rippleDiv.style.height =
            Math.max(targetEl.offsetWidth, targetEl.offsetHeight) + "px";
          targetEl.appendChild(rippleDiv);

          rippleDiv.style.left = e.offsetX - rippleDiv.offsetWidth / 2 + "px";
          rippleDiv.style.top = e.offsetY - rippleDiv.offsetHeight / 2 + "px";
          rippleDiv.classList.add("ripple");
          setTimeout(function () {
            rippleDiv.parentElement.removeChild(rippleDiv);
          }, 600);
        },
        false
      );
    }

    function toggleSidenav() {
      if (body.classList.contains(className)) {
        body.classList.remove(className);
        setTimeout(function () {
          sidenav.classList.remove("bg-white");
        }, 100);
        sidenav.classList.remove("bg-transparent");
      } else {
        body.classList.add(className);
        sidenav.classList.add("bg-white");
        sidenav.classList.remove("bg-transparent");
        iconSidenav.classList.remove("d-none");
      }
    }
  }, []);

  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        ></i>
        <a className="navbar-brand m-0" href="/">
          <span className="me-1 font-weight-bold text-white">
            OGSCOFED Youth
          </span>
          <img src={logo} className="navbar-brand-img h-50" alt="main_logo" />
        </a>
      </div>
      <hr className="horizontal light mt-0 mb-2" />
      <div
        className="collapse navbar-collapse  w-auto "
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className={
                active.active === "dashboard"
                  ? "nav-link text-white active bg-gradient-primary"
                  : "nav-link text-white"
              }
              href="/account/user/dashboard"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">dashboard</i>
              </div>
              <span className="nav-link-text ms-1">Dashboard</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                active.active === "investments"
                  ? "nav-link text-white active bg-gradient-primary"
                  : "nav-link text-white"
              }
              href="/account/user/dashboard/loans"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">payments</i>
              </div>
              <span className="nav-link-text ms-1">Loans</span>
            </a>
          </li>
          <li className="nav-item mt-3">
            <h6 className="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">
              Account pages
            </h6>
          </li>
          <li className="nav-item">
            <a
              className={
                active.active === "profile"
                  ? "nav-link text-white active bg-gradient-primary"
                  : "nav-link text-white"
              }
              href="/account/user/dashboard/profile"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">person</i>
              </div>
              <span className="nav-link-text ms-1">Profile</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                active.active === "referees"
                  ? "nav-link text-white active bg-gradient-primary"
                  : "nav-link text-white"
              }
              href="/account/user/dashboard/referees"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">people</i>
              </div>
              <span className="nav-link-text ms-1">Referees</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="sidenav-footer position-absolute w-100 bottom-0 ">
        <div className="mx-3">
          <a
            className="nav-link text-white fs-5 fw-bold hover-whatsapp"
            href="https://chat.whatsapp.com/I72iQ5BoSWOLqaE41ERXaK"
            target="_blank"
            rel="noreferrer"
          >
            <div className="text-center me-2 d-flex align-items-center justify-content-center">
              <i
                class="fa fa-whatsapp opacity-10 text-primary"
                aria-hidden="true"
              ></i>
              <span className="nav-link-text ms-1">Whatsapp Group</span>
            </div>
          </a>
        </div>
        <div className="mx-3">
          <button
            className="btn bg-gradient-danger mt-4 w-100"
            onClick={logoutUser}
            type="button"
          >
            Sign out
          </button>
        </div>
      </div>
    </aside>
  );
};

const steps = ["Payment"];

const TopNav = () => {

  const axiosCall = useAxios();

  const { user, logoutUser } = useContext(AuthContext);

  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [userData, setUserData] = useState({});
  const [savingsAmount, setSavingsAmount] = useState(0);
  const [xSavingsAmount, setXsavingsAmount] = useState(0);
  const [sharesAmount, setSharesAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [savingsAmountError, setSavingsAmountError] = useState("");
  const [xSavingsAmountError, setXsavingsAmountError] = useState("");
  const [sharesAmountError, setSharesAmountError] = useState("");
  const [totalAmountError, setTotalAmountError] = useState(
    savingsAmount + xSavingsAmount + sharesAmount
  );

  const [debitAccount, setDebitAccount] = useState("extrasavings_debit");
  const [debitAmount, setDebitAmount] = useState(0);

  const [debitAccountError, setDebitAccountError] = useState("");
  const [debitAmountError, setDebitAmountError] = useState("");

  const [othersTitle, setOthersTitle] = useState("");
  const [othersAmount, setOthersAmount] = useState(0);

  const [memberEmail, setMemberEmail] = useState("");
  const [amountDebit, setAmountDebit] = useState(0);

  const [memberEmailError, setMemberEmailError] = useState("");
  const [amountDebitError, setAmountDebitError] = useState("");

  const [regData, setRegData] = useState(true);
  const [payStackData, setPayStackData] = useState(false);
  const [interest, setInterest] = useState(0.0);

  const [refereeStatus, setRefereeStatus] = useState(false);

  const [forYear, setForYear] = useState(null);

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [selected, setSelected] = React.useState("others");

  const [pictureStatus, setPictureStatus] = useState(false);

  const [open, setOpen] = React.useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setTotalAmount(savingsAmount + xSavingsAmount + sharesAmount);
    checkInterest(savingsAmount + xSavingsAmount + sharesAmount);
  };

  const handleNext2 = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    selected === "others" ? checkInterest(othersAmount) : checkInterest(2400);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const closeHandler = () => {
    setVisible2(false);
    setVisible3(false);
    setVisible4(false);
    setVisible5(false);
    setVisible6(false);
  };

  // function to check interest on amount to be paid
  function checkInterest(amount) {
    if (amount < 2500.0) {
      setInterest(Math.trunc(amount * 0.0155));
    } else if (amount === 2500) {
      setInterest(37.5);
    } else {
      const int = amount * 0.0155 + 100;
      setInterest(Math.trunc(int));
      if (int > 2000.0) {
        setInterest(2000.0);
      }
    }

    // return(amount);
  }

  // Function to show tota amount to pay for registration fees
  const ShowRegTotal = () => {
    return (
      <Modal
        closeButton
        blur
        preventClose
        aria-labelledby={`Total-amount-modal`}
        open={visible3}
        onClose={closeHandler}
        scroll
        width="50%"
      >
        <Modal.Header className="d-flex flex-column text-start align-items-start">
          <Text id="modal-title text-success" b size={28}>
            Total amount
          </Text>
          <Text className="text-muted text-small"></Text>
        </Modal.Header>
        <Modal.Body>
          <Text b size={24}>
            You are paying for Registration fee
          </Text>
          <Text b>
            Amount: <span className="fw-normal">&#8358; 2010.00</span>
          </Text>
          <Text b>
            Charges: <span className="fw-normal">&#8358; {interest}</span>
          </Text>
          <Text b>
            Total:{" "}
            <span className="fw-normal">&#8358; {interest + 2000.0}</span>
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            auto
            flat
            color="secondary"
            onPress={() => setVisible3(false)}
          >
            Close
          </Button>
          <Button auto onPress={regPayment}>
            Pay now
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // Function to pay for development fee by calling paystack payment function
  const devPayment = async () => {
    try {
      await axios
        .post(
          `https://youthapi.ogscofed.coop/payments/development-levy/${user.user_id}/`,
          {
            coop_member: user.user_id,
            transaction_amount: 2400.0,
            for_year: forYear ? forYear + 1 : new Date().getFullYear(),
          }
        )
        .then((res) => {
          payWithPayStack(res.data, userData, payStackData, "Development Levy");
        });
    } catch (error) {
      toast.error("You have errors with your payment, try again.");
    }
  };

  // Function to make other payments by calling paystack payment function
  const otherPayment = async () => {
    try {
      await axios
        .post(`https://youthapi.ogscofed.coop/payments/others/${user.user_id}/`, {
          coop_member: user.user_id,
          transaction_amount: othersAmount,
          payment_purpose: othersTitle,
        })
        .then((res) => {
          payWithPayStack(res.data, userData, payStackData, "Other Payments");
        });
    } catch (error) {
      toast.error("You have errors with your payment, try again.");
    }
  };

  // Function to make registration payments by calling paystack payment function
  const regPayment = async () => {
    try {
      await axios
        .post(`https://youthapi.ogscofed.coop/payments/registration/${user.user_id}/`, {
          coop_member: user.user_id,
          transaction_amount: 2010.0,
        })
        .then((res) => {
          setVisible3(false);
          payWithPayStackReg(res.data, userData, payStackData);
        });
    } catch (error) {
      toast.error("You have errors with your payment, try again.");
    }
  };

  // Function to make payments to account by calling paystack payment function
  const makePayment = async () => {
    // setTotalAmount(savingsAmount+xSavingsAmount+sharesAmount);
    const accountData = {
      savings_credit: savingsAmount,
      extrasavings_credit: xSavingsAmount,
      shares_credit: sharesAmount,
      transaction_amount: totalAmount,
    };

    setSavingsAmountError("");
    setXsavingsAmountError("");
    setSharesAmountError("");
    setTotalAmountError("");

    if (totalAmount !== 0) {
      try {
        setLoading(true);
        await axios
          .post(
            `https://youthapi.ogscofed.coop/payments/${user.user_id}/shares-savings/`,
            accountData
          )
          .then((res) => {
            payWithPayStack(
              res.data,
              userData,
              payStackData,
              "Account Payment"
            );
            setVisible2(false);
            setLoading(false);
          });
      } catch (error) {
        toast.error("You have error(s) in your form");
        if (error.response.data.hasOwnProperty("detail")) {
          toast.error(error.response.data["detail"].toString());
        }
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data["message"].toString());
        }
        if (error.response.data.hasOwnProperty("savings_credit")) {
          setSavingsAmountError(
            error.response.data["savings_credit"].toString()
          );
        }
        if (error.response.data.hasOwnProperty("extrasavings_credit")) {
          setXsavingsAmountError(
            error.response.data["extrasavings_credit"].toString()
          );
        }
        if (error.response.data.hasOwnProperty("shares_credit")) {
          setSharesAmountError(error.response.data["shares_credit"].toString());
        }
        if (error.response.data.hasOwnProperty("transaction_amount")) {
          setTotalAmountError(
            error.response.data["transaction_amount"].toString()
          );
        }
      }
    } else {
      toast.error("You have not set amount to pay");
    }
    setLoading(false);
  };

  // Function to make debit request from account
  const makeDebit = async () => {
    if (debitAmount === 0) {
      toast.error("You have not set amount to be debited");
      setLoading(false);
      return;
    }

    const accountData = {
      extrasavings_debit: debitAmount,
      transaction_amount: debitAmount,
    };

    setDebitAccountError("");
    setTotalAmountError("");

    try {
      setLoading(true);
      await axios
        .post(
          `https://youthapi.ogscofed.coop/payments/${user.user_id}/shares-savings/`,
          accountData
        )
        .then((res) => {
          // payWithPayStack(res.data, userData, payStackData, "Account Payment");
          toast.success("Request for account debit has been made");
          setVisible4(false);
          setLoading(false);
        });
    } catch (error) {
      toast.error("You have error(s) in your form");
      if (error.response.data.hasOwnProperty("detail")) {
        toast.error(error.response.data["detail"].toString());
      }
      if (error.response.data.hasOwnProperty("extrasavings_debit")) {
        setDebitAmountError(
          error.response.data["extrasavings_debit"].toString()
        );
      }
      if (error.response.data.hasOwnProperty("transaction_amount")) {
        toast.error(error.response.data["transaction_amount"].toString());
      }
    }
    setLoading(false);
  };

  // Function to make member debit for admin staff
  const debitMember = async () => {
    if (amountDebit === 0) {
      toast.error("You have not set amount to be debited");
      setLoading(false);
      return;
    }

    const accountData = {
      member: memberEmail,
      debit_amount: amountDebit,
    };

    setMemberEmailError("");
    setAmountDebitError("");

    try {
      setLoading(true);
      await axiosCall
        .post(
          `https://youthapi.ogscofed.coop/payments/debit-member/`,
          accountData
        )
        .then((res) => {
          // payWithPayStack(res.data, userData, payStackData, "Account Payment");
          toast.success(res.data["detail"].toString());
          setLoading(false);
          setVisible4(false);
        });
    } catch (error) {
      toast.error("You have error(s) in your form");
      if (error.response.data.hasOwnProperty("detail")) {
        toast.error(error.response.data["detail"].toString());
      }
    }
    setLoading(false);
  };

  // Function to get user data
  const getData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/dashboard/${user.user_id}/`)
        .then((res) => {
          setUserData(res.data);
        });
    } catch (error) {
      toast.error("Unable to get User, Refresh or Try again!");
      logoutUser();
    }
  };

  // Function to get paystack keys
  const getPayStackData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/payments/keys/paystack/`)
        .then((res) => {
          setPayStackData(res.data);
        });
    } catch (error) {}
  };

  // Function to check if user has paid registration fees
  const getRegistrationData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/payments/registration/${user.user_id}/`)
        .then((res) => {
          setRegData(true);
          // toast.info(res.data["message"].toString());
        });
    } catch (error) {
      setRegData(false);
      toast.info(error.response.data["error"].toString());
    }
  };

  // Function to check if user has paid development levy
  const getDevelopmentData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/payments/development-levy/${user.user_id}/`)
        .then((res) => {
          setForYear(res.data[0]["for_year"]);
        });
    } catch (error) {
      toast.info(error.response.data["error"].toString());
    }
  };

  const getReferees = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/${user.user_id}/referee/`)
        .then((res) => {
          setRefereeStatus(true);
        });
    } catch (error) {
      setRefereeStatus(false);
      // toast.error("Unable to get Referees, refresh or try again!");
    }
  };

  const getPicture = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/${user.user_id}/profile-img/`)
        .then((res) => {
          // setUserPicture(res.data);
          setPictureStatus(true);
        });
    } catch (error) {
      setPictureStatus(false);
    }
  };

  useEffect(() => {
    getData();
    getRegistrationData();
    getDevelopmentData();
    getPayStackData();
    getPicture();
    getReferees();
  }, []);

  return (
    <>
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl justify-content-between"
        id="navbarBlur"
        data-scroll="true"
        aria-labelledby="iconNavbarSidenav"
      >
        <div className="container-fluid py-1 px-3 align-items-center justify-content-end">
          <h5 className="mb-0">
            Welcome,{" "}
            <span className="font-weight-bolder">
              {userData.first_name} {userData.last_name}
            </span>
          </h5>
          {regData ? (
            <>
              <button
                className="btn bg-gradient-primary mb-0 ms-auto"
                onClick={(e) => {
                  setVisible6(true);
                }}
                type="button"
              >
                Pay Dues
              </button>
            </>
          ) : (
            <>
              <button
                className="btn bg-gradient-primary mb-0 ms-auto"
                onClick={(e) => {
                  checkInterest(2000.0);
                  setVisible3(true);
                }}
                type="button"
              >
                Pay Registration fee
              </button>
              <ShowRegTotal />
            </>
          )}
          {userData.is_staff ? (
            <button
              className="btn bg-gradient-primary mb-0 ms-auto"
              onClick={(e) => {setOpen(true); setVisible5(true)}}
              // disabled={
              //   pictureStatus && regData && refereeStatus ? false : true
              // }
              type="button"
            >
              Debit user
            </button>
          ) : null}
          <button
            className="btn bg-gradient-primary mb-0 ms-auto"
            onClick={(e) => setVisible4(true)}
            disabled={pictureStatus && regData && refereeStatus ? false : true}
            type="button"
          >
            Request debit
          </button>
          <button
            className="btn bg-gradient-primary mb-0 ms-auto"
            onClick={(e) => setVisible2(true)}
            disabled={pictureStatus && regData && refereeStatus ? false : true}
            type="button"
          >
            Make payment
          </button>

          <li className="nav-item d-xl-none ps-3 d-flex align-items-center mb-0">
            <button
              className="nav-link text-body p-0 btn-link"
              id="iconNavbarSidenav"
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </button>
          </li>
        </div>
      </nav>

      {/* Modal for account payments */}
      <Modal
        closeButton
        blur
        preventClose
        aria-labelledby="modal-title"
        open={visible2}
        onClose={closeHandler}
      >
        <Modal.Header className="flex-column">
          <Text id="modal-title" size={18} className="mb-1">
            Make payment
          </Text>
        </Modal.Header>
        <Modal.Body>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                The following payment will be made into your account
              </Typography>
              <Box mt={2}>
                {savingsAmount !== 0 ? (
                  <Typography>
                    Savings:{" "}
                    <span className="fw-bold">&#8358;{savingsAmount}</span>
                  </Typography>
                ) : (
                  <></>
                )}
                {xSavingsAmount !== 0 ? (
                  <Typography>
                    Extra Savings:{" "}
                    <span className="fw-bold">&#8358;{xSavingsAmount}</span>
                  </Typography>
                ) : (
                  <></>
                )}
                {sharesAmount !== 0 ? (
                  <Typography>
                    Shares:{" "}
                    <span className="fw-bold">&#8358;{sharesAmount}</span>
                  </Typography>
                ) : (
                  <></>
                )}
                <Typography>
                  Charges: <span className="fw-bold">&#8358;{interest}</span>
                </Typography>
                <Typography>
                  Total transaction amount:{" "}
                  <span className="fw-bold">
                    &#8358;{totalAmount + interest}
                  </span>
                </Typography>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                className="alert alert-primary alert-dismissible text-white mx-3 mb-3 text-center"
                role="alert"
              >
                <span className="text-sm">
                  Leave field you are not paying in zero
                </span>
                <button
                  type="button"
                  className="btn-close text-sm py-3 opacity-10"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <MdClose fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                  severity="info"
                >
                  Leave field in zero, if you are not paying
                </Alert>
              </Collapse>
              <TextField
                label="Savings amount"
                fullWidth
                required
                clearable
                variant="standard"
                onChange={(e) => {
                  setSavingsAmount(parseInt(e.target.value, 10));
                }}
                name="savings amount"
                value={savingsAmount}
                error={!!savingsAmountError}
                helperText={!!savingsAmountError ? savingsAmountError : ""}
                InputProps={{
                  // startAdornment: <InputAdornment position="start"><strong>&#8358;</strong></InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
              />
              <TextField
                className="mt-2"
                label="Xtra savings amount"
                fullWidth
                required
                clearable
                variant="standard"
                onChange={(e) => {
                  setXsavingsAmount(parseInt(e.target.value, 10));
                }}
                name="Xsavings amount"
                value={xSavingsAmount}
                error={!!xSavingsAmountError}
                helperText={!!xSavingsAmountError ? xSavingsAmountError : ""}
                InputProps={{
                  // startAdornment: <InputAdornment position="start"><strong>&#8358;</strong></InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
              />
              <TextField
                className="mt-2"
                label="Shares amount"
                fullWidth
                required
                clearable
                variant="standard"
                onChange={(e) => {
                  setSharesAmount(parseInt(e.target.value, 10));
                }}
                name="shares amount"
                value={sharesAmount}
                error={!!sharesAmountError}
                helperText={!!sharesAmountError ? sharesAmountError : ""}
                InputProps={{
                  // startAdornment: <InputAdornment position="start"><strong>&#8358;</strong></InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
              />
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                size={"sm"}
                // sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button size={"sm"} disabled={loading} onClick={makePayment}>
                {loading ? (
                  <Loading
                    textColor={"primary"}
                    color="currentColor"
                    type="points"
                    size="sm"
                  />
                ) : (
                  "Pay Now"
                )}
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button auto flat color="error" onPress={closeHandler}>
                Close
              </Button>
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </React.Fragment>
          )}
        </Modal.Footer>
      </Modal>

      {/* Modal for account debit */}
      <Modal
        closeButton
        blur
        preventClose
        aria-labelledby="modal-title"
        open={visible4}
        onClose={closeHandler}
      >
        <Modal.Header className="flex-column">
          <Text id="modal-title" size={18} className="mb-1">
            Request debit
          </Text>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              The following payment will be made into your account
            </Typography>
            <Box mt={2}>
              <TextField
                label="Account"
                fullWidth
                required
                variant="standard"
                select
                disabled
                onChange={(e) => {
                  setDebitAccount(e.target.value);
                }}
                name="account"
                defaultValue={"xtrasavings_debit"}
                value={debitAccount}
                error={!!debitAccountError}
                helperText={!!debitAccountError ? debitAccountError : ""}
              >
                <MenuItem value="savings_debit">Savings</MenuItem>
                <MenuItem value="extrasavings_debit">Extra-Savings</MenuItem>
                <MenuItem value="shares_debit">Shares</MenuItem>
              </TextField>
              <TextField
                label="Debit amount"
                fullWidth
                required
                clearable
                variant="standard"
                onChange={(e) => {
                  setDebitAmount(parseInt(e.target.value, 10));
                }}
                name="savings amount"
                value={debitAmount}
                error={!!debitAmountError}
                helperText={!!debitAmountError ? debitAmountError : ""}
                InputProps={{
                  // startAdornment: <InputAdornment position="start"><strong>&#8358;</strong></InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
              />
            </Box>
          </React.Fragment>
          {/* {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                The following payment will be made into your account
              </Typography>
              <Box mt={2}>
                {savingsAmount !== 0 ? (
                  <Typography>
                    Savings:{" "}
                    <span className="fw-bold">&#8358;{savingsAmount}</span>
                  </Typography>
                ) : (
                  <></>
                )}
                {xSavingsAmount !== 0 ? (
                  <Typography>
                    Extra Savings:{" "}
                    <span className="fw-bold">&#8358;{xSavingsAmount}</span>
                  </Typography>
                ) : (
                  <></>
                )}
                {sharesAmount !== 0 ? (
                  <Typography>
                    Shares:{" "}
                    <span className="fw-bold">&#8358;{sharesAmount}</span>
                  </Typography>
                ) : (
                  <></>
                )}
                <Typography>
                  Charges: <span className="fw-bold">&#8358;{interest}</span>
                </Typography>
                <Typography>
                  Total transaction amount:{" "}
                  <span className="fw-bold">
                    &#8358;{totalAmount + interest}
                  </span>
                </Typography>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                className="alert alert-primary alert-dismissible text-white mx-3 mb-3 text-center"
                role="alert"
              >
                <span className="text-sm">
                  Leave field you are not paying in zero
                </span>
                <button
                  type="button"
                  className="btn-close text-sm py-3 opacity-10"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <TextField
                label="Savings amount"
                fullWidth
                required
                clearable
                variant="standard"
                onChange={(e) => {
                  setSavingsAmount(parseInt(e.target.value, 10));
                }}
                name="savings amount"
                value={savingsAmount}
                error={!!savingsAmountError}
                helperText={!!savingsAmountError ? savingsAmountError : ""}
                InputProps={{
                  // startAdornment: <InputAdornment position="start"><strong>&#8358;</strong></InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
              />
              <TextField
                className="mt-2"
                label="Xtra savings amount"
                fullWidth
                required
                clearable
                variant="standard"
                onChange={(e) => {
                  setXsavingsAmount(parseInt(e.target.value, 10));
                }}
                name="Xsavings amount"
                value={xSavingsAmount}
                error={!!xSavingsAmountError}
                helperText={!!xSavingsAmountError ? xSavingsAmountError : ""}
                InputProps={{
                  // startAdornment: <InputAdornment position="start"><strong>&#8358;</strong></InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
              />
              <TextField
                className="mt-2"
                label="Shares amount"
                fullWidth
                required
                clearable
                variant="standard"
                onChange={(e) => {
                  setSharesAmount(parseInt(e.target.value, 10));
                }}
                name="shares amount"
                value={sharesAmount}
                error={!!sharesAmountError}
                helperText={!!sharesAmountError ? sharesAmountError : ""}
                InputProps={{
                  // startAdornment: <InputAdornment position="start"><strong>&#8358;</strong></InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
              />
            </React.Fragment>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <React.Fragment>
            <Button size={"sm"} disabled={loading} onClick={makeDebit}>
              {loading ? (
                <Loading
                  textColor={"primary"}
                  color="currentColor"
                  type="points"
                  size="sm"
                />
              ) : (
                "Request debit"
              )}
            </Button>
          </React.Fragment>
          {/* {activeStep === steps.length ? (
            <React.Fragment>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                size={"sm"}
                // sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button size={"sm"} disabled={loading} onClick={makePayment}>
                {loading ? (
                  <Loading
                    textColor={"primary"}
                    color="currentColor"
                    type="points"
                    size="sm"
                  />
                ) : (
                  "Pay Now"
                )}
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button auto flat color="error" onPress={closeHandler}>
                Close
              </Button>
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </React.Fragment>
          )} */}
        </Modal.Footer>
      </Modal>

      {/* Modal for other payments and develpment levy */}
      <Modal
        closeButton
        blur
        preventClose
        aria-labelledby="modal-title"
        open={visible6}
        onClose={closeHandler}
      >
        <Modal.Header className="flex-column">
          <Text id="modal-title" size={18} className="mb-1">
            Dues Payments
          </Text>
        </Modal.Header>
        <Modal.Body>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                You are paying for&nbsp;{" "}
                <span className="fw-bold">
                  {selected === "others" ? othersTitle : "Development levy"}
                </span>
              </Typography>
              <Box mt={2}>
                {selected === "others" ? (
                  <Typography>
                    Transaction Amount:&nbsp;
                    <span className="fw-bold">&#8358;{othersAmount}</span>
                  </Typography>
                ) : (
                  <Typography>
                    Development Levy:&nbsp;
                    <span className="fw-bold">&#8358;2400.00</span>
                  </Typography>
                )}
                <Typography>
                  Charges:&nbsp;
                  <span className="fw-bold">&#8358;{interest}</span>
                </Typography>
                <Typography>
                  Total transaction amount:&nbsp;
                  <span className="fw-bold">
                    &#8358;
                    {selected === "others"
                      ? othersAmount + interest
                      : 2400.0 + interest}
                  </span>
                </Typography>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Radio.Group
                orientation="horizontal"
                value={selected}
                onChange={setSelected}
                label="Type of Payment"
              >
                <Radio color="primary" value="others">
                  Others
                </Radio>
                <Radio color="primary" value="dev-levy">
                  Development Levy
                </Radio>
              </Radio.Group>
              {selected === "others" ? (
                <>
                  <TextField
                    label="Payment purpose"
                    fullWidth
                    required
                    clearable
                    variant="standard"
                    onChange={(e) => {
                      setOthersTitle(e.target.value);
                    }}
                    name="payment purpose"
                    value={othersTitle}
                  />
                  <TextField
                    className="mt-2"
                    label="Transaction amount"
                    fullWidth
                    required
                    clearable
                    variant="standard"
                    onChange={(e) => {
                      setOthersAmount(parseInt(e.target.value, 10));
                    }}
                    name="transaction amount"
                    value={othersAmount}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label="Development levy"
                    fullWidth
                    required
                    disabled
                    variant="standard"
                    name="development levy"
                    value={2400}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                  <TextField
                    className="mt-2"
                    label="For year"
                    fullWidth
                    required
                    disabled
                    variant="standard"
                    name="for year"
                    value={forYear ? forYear + 1 : new Date().getFullYear()}
                    // InputProps={{
                    //   inputComponent: NumericFormatCustom,
                    // }}
                  />
                </>
              )}
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                size={"sm"}
              >
                Back
              </Button>
              <Button
                size={"sm"}
                disabled={loading}
                onClick={selected === "others" ? otherPayment : devPayment}
              >
                {loading ? (
                  <Loading
                    textColor={"primary"}
                    color="currentColor"
                    type="points"
                    size="sm"
                  />
                ) : (
                  "Pay Now"
                )}
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button auto flat color="error" onPress={closeHandler}>
                Close
              </Button>
              <Button onClick={handleNext2}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </React.Fragment>
          )}
        </Modal.Footer>
      </Modal>

      {/* Modal to debit user for admins */}
      <Modal
        closeButton
        aria-labelledby="Bank-details-modal"
        open={visible5}
        onClose={closeHandler}
        preventClose
      >
        <Modal.Header>
          <Typography variant="h6">Add Bank Details</Typography>
        </Modal.Header>
        <Modal.Body>
          <TextField
            label="Member email"
            fullWidth
            required
            variant="standard"
            type="email"
            onChange={(e) => {
              setMemberEmail(e.target.value);
            }}
            name="member email"
            value={memberEmail}
            error={!!memberEmailError}
            helperText={!!memberEmailError ? memberEmailError : ""}
          />
          <TextField
            label="Debit amount"
            fullWidth
            required
            variant="standard"
            onChange={(e) => {
              setAmountDebit(e.target.value);
            }}
            name="debit amount"
            value={amountDebit}
            error={!!amountDebitError}
            helperText={!!amountDebitError ? amountDebitError : ""}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <MdClose fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, mt: 2 }}
              severity="info"
            >
              Confirm all details before submitting
            </Alert>
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onClick={closeHandler}>
            Close
          </Button>
          <Button auto onClick={debitMember} disabled={loading}>
            {loading ? (
              <Loading
                textColor={"primary"}
                color="currentColor"
                type="points"
                size="sm"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DashboardFooter = () => {
  return (
    <footer class="footer py-4  ">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-lg-6 mb-lg-0 mb-4">
            <div class="copyright text-center text-sm text-muted text-lg-start">
              © <script>document.write(new Date().getFullYear())</script>2023,
              made with <i class="fa fa-heart" aria-hidden="true"></i> for{" "}
              <a href="/" class="font-weight-bold">
                OGSCOFED
              </a>{" "}
              Youth COOP
            </div>
          </div>
          <div class="col-lg-6">
            <ul class="nav nav-footer justify-content-center justify-content-lg-end">
              <li class="nav-item">
                <a href="/" class="nav-link text-muted">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://twitter.com/oguncoopyouth"
                  class="nav-link text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-twitter" aria-hidden="true"></i>
                  {/* Twitter */}
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://www.facebook.com/oguncoopyouth"
                  class="nav-link text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                  {/* Facebook */}
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="/docs/byeLaw.pdf"
                  class="nav-link pe-0 text-muted"
                  target="_blank"
                  download
                >
                  Bye laws
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="/docs/terms.pdf"
                  class="nav-link pe-0 text-muted"
                  target="_blank"
                  download
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { SideNav, TopNav, DashboardFooter };
