import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import Scrollbar from "perfect-scrollbar-react";
import { toast } from "react-toastify";

import { Modal, Text, Link, Button, Loading } from "@nextui-org/react";
import { MdVisibility, MdVisibilityOff, MdCircle } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { TextField, Grid, Box, Typography } from "@mui/material";

import PerfectScrollbar from "perfect-scrollbar";

import Footer from "../../Components/Navs/footer";
import NavbarComp from "../../Components/Navs/topNav";

import side from "../../static/images/side.jpg";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [email2, setEmail2] = useState("");
  const [email2Error, setEmail2Error] = useState("");

  const [status, setStatus] = useState("");
  const [visible2, setVisible2] = useState(false);

  const steps = ["Reset"];

  const { loginUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    setLoading(true);
    setEmailError("");

    const userData = {
      email: email2,
    };

    try {
      await axios
        .post(`https://youthapi.ogscofed.coop/account/request-reset-email/`, userData)
        .then((res) => {
          closeHandler();
          toast.success(res.data["success"].toString());
        });
    } catch (error) {
      toast.error("You have error in your form");
      if (error.response.data.hasOwnProperty("error")) {
        setEmail2Error(error.response.data["error"]);
      }
    }
    setLoading(false);
  };

  const closeHandler = () => {
    setVisible2(false);
  };

  const onLoginClick = () => {
    // e.preventDefault()
    setLoading(true);
    setEmailError("");
    setPasswordError("");
    setStatus("");

    const errorData = {
      setEmailError,
      setPasswordError,
      setLoading,
    };

    const userData = {
      email: email,
      password: password,
    };
    loginUser(userData, errorData);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const form = (
    <Grid container spacing={0} className="">
      <Grid item xs={0} sm={5} className="w-100 side-container">
        <Box className="w-100 vh-100 d-flex flex-column">
          <img
            src={side}
            className="img-cover"
            width="100%"
            height="100%"
            alt="Signup vector"
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        mt={10}
        sx={{ pl: { xs: 5, lg: 15 }, pr: { xs: 5, lg: 10 } }}
      >
        <Typography className="mt-3">
          Don't have an account?{" "}
          <Link href="/account/register" color={"secondary"}>
            Register
          </Link>
          &nbsp;here
        </Typography>
        <Typography
          component={"h1"}
          mt={10}
          fontWeight={"700"}
          fontSize={"3rem"}
          lineHeight={"100%"}
        >
          Welcome back, <br />
          ple
          <Typography
            fontWeight={"700"}
            fontSize={"3rem"}
            component={"span"}
            color="primary"
          >
            a
          </Typography>
          se Log in
          <Typography
            component={"span"}
            color="primary"
            size={"0.5em"}
            css={{ marginLeft: "0.15em", marginTop: "0.1em" }}
          >
            <MdCircle />
          </Typography>
        </Typography>
        <Box component={"form"} noValidate autoComplete="off">
          <Grid container mt={7} spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label="Email address"
                fullWidth
                required
                variant="standard"
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                type="email"
                error={!!emailError}
                helperText={!!emailError ? emailError : ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl
                fullWidth
                variant="standard"
                error={!!passwordError}
                required
                autoComplete="new-password"
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {!!passwordError ? passwordError : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item xs={12}>
              <Button
                color="primary"
                // fullWidth
                type="submit"
                disabled={loading}
                onClick={onLoginClick}
              >
                {loading ? (
                  <Loading
                    textColor={"primary"}
                    color="currentColor"
                    type="points"
                    size="sm"
                  />
                ) : (
                  "Login"
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography mt={3}>
                Forgot password?{" "}
                <Link onClick={(e) => setVisible2(true)} color={"secondary"}>
                  Reset password
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>        
        <Footer />
      </Grid>
    </Grid>
  );

  return (
    <main className="">
      <NavbarComp />
      <Box>
        {form}
      </Box>
      <Modal
        closeButton
        blur
        preventClose
        aria-labelledby="modal-title"
        open={visible2}
        onClose={closeHandler}
      >
        <Modal.Header className="flex-column">
          <Text id="modal-title" size={18} className="mb-1">
            Enter email address associated with your account
          </Text>
        </Modal.Header>
        <Modal.Body>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                The following payment will be made into your account
              </Typography>
              <Box mt={2}></Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextField
                label="Email address"
                fullWidth
                required
                variant="standard"
                onChange={(e) => setEmail2(e.target.value)}
                name="email"
                type="email"
                error={!!email2Error}
                helperText={!!email2Error ? email2Error : ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={closeHandler}
                size={"sm"}
                // sx={{ mr: 1 }}
              >
                Close
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button auto flat color="error" onPress={closeHandler}>
                Cancel
              </Button>
              <Button onClick={handleNext} disabled={loading}>
                {loading ? (
                  <Loading
                    textColor={"primary"}
                    color="currentColor"
                    type="points"
                    size="sm"
                  />
                ) : (
                  "Reset password"
                )}
              </Button>
            </React.Fragment>
          )}
        </Modal.Footer>
      </Modal>
    </main>
  );
}

export default Login;
