import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Home from "../../homePage"

import { Container } from "react-bootstrap";
import { Modal, Card, Col, Row, Link, Button, Text } from "@nextui-org/react";
import { GiCheckMark, GiCrossMark } from "react-icons/gi"
import on from "../../../static/images/on.png"
import off from "../../../static/images/off.png"

import Footer from "../../../Components/Navs/footer";
import NavbarComp from "../../../Components/Navs/topNav";


function Activate () {
    const history = useHistory();
    const [visible, setVisible] = useState(true);
    const [status, setStatus] = useState("");

    const [data, setData] = useState([]);
    const { token } = useParams();
    const activation = async () => {
        const response = await fetch(`https://youthapi.ogscofed.coop/account/email-verify/${token}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
        });

        const data = await response.json();
        setData(data);
        if (response.status===200) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }

    useEffect(() => {
        activation();
    }, []);

    const closeHandler = () => {
        setVisible(false);
        history.push('/')
    };

    
    return (
    <>
    <NavbarComp />
    <Container>
      <Home />
      <div>
      <Modal
        closeButton
        preventClose
        noPadding
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
        css={{ paddingTop:"$0" }}>
        <Modal.Body css={{ bg: "$black",w: "100%", h: "400px" }}>
            <Card css={{ w: "100%", h: "400px" }} variant="flat">
                <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
                    <Col>
                        <Text size={16} weight="bold" transform="uppercase" color={status ? '$success' : '$error'}>
                        {status ? 'Account Activated' : 'Account not activated'}
                        </Text>
                        <Text h3 color="white">
                        {status ? `${data.message}` : `${data.error}`}
                        </Text>
                    </Col>
                </Card.Header>
                <Card.Body css={{ p: 0 }}>
                    <Card.Image
                        src={status ? on : off}
                        objectFit="cover"
                        width="100%"
                        height="100%"
                        alt="Relaxing app background"
                    />
                </Card.Body>
                <Card.Footer
                    isBlurred
                    css={{
                        position: "absolute",
                        bgBlur: "#0f111466",
                        borderTop: "$borderWeights$light solid $gray800",
                        bottom: 0,
                        zIndex: 1,
                    }}
                    >
                    <Row>
                        <Col>
                        <Row>
                            <Col span={6}>
                            <Text size={24} color={status ? '$success' : '$error'}>{status ? <GiCheckMark /> : <GiCrossMark/>}</Text>
                            </Col>
                            <Col>
                            <Text color="#d1d1d1" size={12}>
                                {status ? "Your account has been successfully registered, you can log in now." : 
                                "Your account could not be activated, you can register again or contact support."}
                            </Text>
                            </Col>
                        </Row>
                        </Col>
                        <Col>
                        <Row justify="flex-end">
                            <Button
                            flat
                            auto
                            rounded
                            as={Link}
                            href={status ? "/account/login" : "/account/register"}
                            // css={{ color: "#94f9f0", bg: "#94f9f026" }}
                            >
                            <Text
                                css={{ color: "inherit" }}
                                size={12}
                                weight="bold"
                                transform="uppercase"
                            >
                                {status ? "Log in" : "Sign up"}
                            </Text>
                            </Button>
                        </Row>
                        </Col>
                    </Row>
                    </Card.Footer>
            </Card>
        </Modal.Body>
      </Modal>
      </div>
    </Container>
    </>
    );
}

export default Activate;