import { Box, Typography } from "@mui/material";
import { jsPDF } from "jspdf";

import logo from "../static/images/logo.png";
import dayjs from "dayjs";

function download(data, type) {
  var doc = new jsPDF();
  var title = type === 'Registration' ? data[2] : data['transaction_id']

  doc.html(document.getElementById("receipt"), {
    callback: function (doc) {
      doc.save(`${title}_receipt`);
    },
    margin: 5,
    windowWidth: 100,
    autoPaging: "text",
  });
}

const DataValues = ({ data, type }) => {
  return (
    <Box id="receipt" sx={{ width: "200px", p: 2, position: "fixed" }}>
      <Box className="d-flex align-items-center">
        <img src={logo} width={"30%"} className="mx-auto" />
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: "1.5rem",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 400,
          mt: 1,
          lineHeight: "100%",
        }}
      >
        {`${type} receipt`}
      </Typography>
      {type === "Account" ? (
        <>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
              mt: 2
            }}
          >
            Transactions:
          </Typography>
          {data.savings_credit === 0 ? null : (
            <Typography
              sx={{
                fontSize: "0.5rem",
                fontFamily: "Roboto",
                fontWeight: 400,
                lineHeight: "100%",
              }}
            >
              Savings:{" "}
              <span className="text-muted">&#8358;{data.savings_credit}</span>
            </Typography>
          )}
          {data.extrasavings_credit === 0 ? null : (
            <Typography
              sx={{
                fontSize: "0.5rem",
                fontFamily: "Roboto",
                fontWeight: 400,
                lineHeight: "100%",
              }}
            >
              Extra-Savings:{" "}
              <span className="text-muted">
                &#8358;{data.extrasavings_credit}
              </span>
            </Typography>
          )}
          {data.shares_credit === 0 ? null : (
            <Typography
              sx={{
                fontSize: "0.5rem",
                fontFamily: "Roboto",
                fontWeight: 400,
                lineHeight: "100%",
              }}
            >
              Shares:{" "}
              <span className="text-muted">&#8358;{data.shares_credit}</span>
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              mt: 1,
              lineHeight: "100%",
            }}
          >
            Transaction Date:{" "}
            <span className="text-muted">
              {dayjs(data.transaction_date).format("ddd MMM D, YYYY")}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              mt: 1,
              lineHeight: "100%",
            }}
          >
            Transaction Time:{" "}
            <span className="text-muted">
              {dayjs(data.transaction_date).format("h:m A")}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              mt: 1,
              lineHeight: "100%",
            }}
          >
            Total Transaction Amount:{" "}
            <span className="text-muted">&#8358;{data.transaction_amount}</span>
          </Typography>
          <Box
            component={"div"}
            sx={{
              border: data.payment_verified
                ? "3px solid green"
                : "3px solid red",
              p: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              //   position: "absolute",
              //   top: 100,
              //   left: 70,
              //   transform: 'rotate(-30deg)',
              //   rotateZ: "-30deg",
              opacity: "40%",
              mt: 1
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 700,
                lineHeight: "100%",
                mx: "auto",
                color: data.payment_verified ? "green" : "red",
              }}
            >
              {data.payment_verified ? "PAID" : "NOT PAID"}
            </Typography>
          </Box>
        </>
      ) : null}
      {type === "Registration" ? (
        <>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
              mt: 2
            }}
          >
            Transactions: <span className="text-muted">Registration Fee</span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Transaction date:{" "}
            <span className="text-muted">
              {dayjs(data[1]).format("ddd MMM D, YYYY")}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Transaction time:{" "}
            <span className="text-muted">{dayjs(data[1]).format("h:m A")}</span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Total Transaction Amount:{" "}
            <span className="text-muted">&#8358; {data[3]}</span>
          </Typography>
          <Box
            component={"div"}
            sx={{
              border: "3px solid green",
              p: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              //   position: "absolute",
              //   top: 100,
              //   left: 70,
              //   transform: 'rotate(-30deg)',
              //   rotateZ: "-30deg",
              opacity: "40%",
              mt: 1
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 700,
                lineHeight: "100%",
                mx: "auto",
                color: "green",
              }}
            >
              PAID
            </Typography>
          </Box>
        </>
      ) : null}
      {type === "Development Levy" ? (
        <>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
              mt: 2
            }}
          >
            Transactions:{" "}
            <span className="text-muted">
              Development Levy for {data.for_year}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Transaction date:{" "}
            <span className="text-muted">
              {dayjs(data.payment_date).format("ddd MMM D, YYYY")}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Transaction time:{" "}
            <span className="text-muted">
              {dayjs(data.payment_date).format("h:m A")}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Total Transaction Amount:{" "}
            <span className="text-muted">
              &#8358; {data.transaction_amount}
            </span>
          </Typography>
          <Box
            component={"div"}
            sx={{
              border: "3px solid green",
              p: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              //   position: "absolute",
              //   top: 100,
              //   left: 70,
              //   transform: 'rotate(-30deg)',
              //   rotateZ: "-30deg",
              opacity: "40%",
              mt: 1
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 700,
                lineHeight: "100%",
                mx: "auto",
                color: "green",
              }}
            >
              PAID
            </Typography>
          </Box>
        </>
      ) : null}
      {type === "Other Levy" ? (
        <>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
              mt: 2
            }}
          >
            Payment for:{" "}
            <span className="text-muted">
              {data.payment_purpose}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Transaction date:{" "}
            <span className="text-muted">
              {dayjs(data.payment_date).format("ddd MMM D, YYYY")}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Transaction time:{" "}
            <span className="text-muted">
              {dayjs(data.payment_date).format("h:m A")}
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "0.5rem",
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Total Transaction Amount:{" "}
            <span className="text-muted">
              &#8358; {data.transaction_amount}
            </span>
          </Typography>
          <Box
            component={"div"}
            sx={{
              border: "3px solid green",
              p: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              //   position: "absolute",
              //   top: 100,
              //   left: 70,
              //   transform: 'rotate(-30deg)',
              //   rotateZ: "-30deg",
              opacity: "40%",
              mt: 1
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontFamily: "Roboto",
                fontWeight: 700,
                lineHeight: "100%",
                mx: "auto",
                color: "green",
              }}
            >
              PAID
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export { download, DataValues };
