import { Link, Text, Divider, Image } from "@nextui-org/react";
import { Container, Row, Col } from "reactstrap";
import { FaTwitter, FaLinkedinIn, FaFacebookF, FaLinkedin } from "react-icons/fa";

import logo from "../../static/images/logo.png";
import { Grid } from "@mui/material";

const Footer = () => {
  return (
    <Container className="mt-5 py-3">
      <Divider variant="middle" className="position-static" />
      <Grid container className="mb-3 mt-5 justify-content-between">
        <Grid item xs={12} sm={3} xl={4} className="align-self-start">
          <Grid container>
            <Grid item xs={12} className="h-100 footer-logo">
              <Image
                src={logo}
                width={"40%"}
                height={"40%"}
                className="mx-0"
              />
              {/* <Text b className='w-auto align-self-center ps-0'>Youth Cooperative</Text> */}
            </Grid>
            <Grid
              item
              xs={0}
              sm={12}
              className="py-1 align-items-center"
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <FaTwitter className="w-auto px-0 pe-1" />
              <Link
                isExternal
                href="https://twitter.com/oguncoopyouth"
                target="_blank"
                className="text-muted px-0"
              >
                Twitter
              </Link>
            </Grid>
            <Grid
              item
              xs={0}
              sm={12}
              className="py-1 align-items-center"
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <FaFacebookF className="w-auto px-0 pe-1" />
              <Link
                isExternal
                href="https://www.facebook.com/oguncoopyouth"
                target="_blank"
                className="text-muted px-0"
              >
                Facebook
              </Link>
            </Grid>
            <Grid
              item
              xs={0}
              sm={12}
              className="py-1 align-items-center"
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <FaLinkedinIn className="w-auto px-0 pe-1" />
              <Link className="text-muted px-0">LinkedIn</Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={0} sx={{ display: { xs: "block", sm: "none" } }}>
          <Row className="py-1 align-items-center">
            <FaTwitter className="w-auto px-0 pe-1" />
            <Link
              isExternal
              href="https://twitter.com/oguncoopyouth"
              target="_blank"
              className="text-muted px-0"
            >
              Twitter
            </Link>
          </Row>
          <Row className="py-1 align-items-center">
            <FaFacebookF className="w-auto px-0 pe-1" />
              <Link
                isExternal
                href="https://www.facebook.com/oguncoopyouth"
                target="_blank"
                className="text-muted px-0"
              >
                Facebook
              </Link>
          </Row>
          <Row className="py-1 align-items-center">
            <FaLinkedinIn className="w-auto px-0 pe-1" />
            <Link
              isExternal
              target="_blank"
              className="text-muted px-0"
            >
              Linkedin
            </Link>
          </Row>
        </Grid>
        <Grid item xs={4} sm={3} xl={2}>
          <Row className="pb-1">
            <Text b>Payments</Text>
          </Row>
          <Row className="py-1">
            <Link className="text-muted">Savings</Link>
          </Row>
          <Row className="py-1">
            <Link className="text-muted">Investments</Link>
          </Row>
          <Row className="py-1">
            <Link className="text-muted">Loans</Link>
          </Row>
        </Grid>
        <Grid item xs={4} sm={3} xl={2}>
          <Row className="pb-1">
            <Text b>Cooperative</Text>
          </Row>
          <Row className="py-1">
            <Link className="text-muted">Meetings</Link>
          </Row>
          <Row className="py-1">
            <Link className="text-muted">Events</Link>
          </Row>
          <Row className="py-1">
            <Link href="/about" className="text-muted">
              About Us
            </Link>
          </Row>
          <Row className="py-1">
            <Link
              href="/docs/byeLaw.pdf"
              target="_blank"
              download
              className="text-muted"
            >
              Bye laws
            </Link>
          </Row>
          <Row className="py-1">
            <Link
              href="/docs/terms.pdf"
              target="_blank"
              download
              className="text-muted"
            >
              Terms & conditions
            </Link>
          </Row>
        </Grid>
      </Grid>
      <Text className="text-center mb-0 mt-2">
        For Enquiries, information, and Sponsorship, Contact{" "}
        <a className="text-secondary fw-bold" href="tel:+2348072860376">
          08072860376
        </a>
        ,{" "}
        <a className="text-secondary fw-bold" href="tel:+2348144800662">
          08144800662
        </a>{" "}
      </Text>
      <Text className="text-center">
        Send us a mail at{" "}
        <a
          className="text-secondary fw-bold"
          href="mailto:oguncoopyouth@gmail.com"
        >
          oguncoopyouth@gmail.com
        </a>
        , we'd love to hear from you
      </Text>
      <Text className="text-center mt-5" h6>
        Copyright &copy; 2023{" "}
        <span className="fw-normal">Ogun State Youth Cooperative Society</span>
      </Text>
    </Container>
  );
};

export default Footer;
