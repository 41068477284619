// import { Link } from "react-router-dom";
import { Link } from "@nextui-org/react";
import NavbarComp from "../Components/Navs/topNav";
import { Container } from "reactstrap";

import fof from "../assets/img/illustrations/error-404.png";
import { Box, Grid } from "@mui/material";

export default function NotFound() {
  return (
    <>
      <NavbarComp />
      <Container className="mt-5">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <img src={fof} alt="404 not found" width={"100%"} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1>Oops! You seem to be lost.</h1>
            <h2>Here are some helpful links:</h2>
            <Box sx={{ display: "flex" }}>
              <Link
                css={{
                  fontSize: "$2xl",
                  color: "$secondary",
                  fontWeight: "$extrabold",
                }}
                href="/"
              >
                Home
              </Link>
              {/* <br /> */}
              <Link
                css={{
                  marginLeft: "$10",
                  fontSize: "$2xl",
                  color: "$secondary",
                  fontWeight: "$extrabold",
                }}
                href="/account/login"
              >
                Login
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
