import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { PatternFormat } from "react-number-format";
import PropTypes from "prop-types";
import NaijaStates from "naija-state-local-government";
import $ from "jquery";

import {
  Box,
  TextField,
  IconButton,
  Input,
  FormHelperText,
  InputAdornment,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import {
  Text,
  Button,
  Link,
  Loading,
  Modal,
  Row as Nrow,
  Checkbox,
} from "@nextui-org/react";
import { MdVisibility, MdVisibilityOff, MdCircle } from "react-icons/md";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IoShieldCheckmarkSharp } from "react-icons/io5";

import Footer from "../../../Components/Navs/footer";
import NavbarComp from "../../../Components/Navs/topNav";

import side from "../../../static/images/side.jpg";
import isInViewport from "../../../Components/endTerms";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="##########"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Signup() {
  const history = useHistory();

  const [selected, setSelected] = React.useState(false);
  const [read, setRead] = React.useState(false);
  const [reachedEnd, setReachedEnd] = React.useState(false);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [other_name, setOtherName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [states, setStates] = useState("");
  const [city, setCity] = useState("");
  const [occupation, setOccupation] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [first_nameError, setFirstNameError] = useState("");
  const [last_nameError, setLastNameError] = useState("");
  const [other_nameError, setOtherNameError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [statesError, setStatesError] = useState("");
  const [occupationError, setOccupationError] = useState("");
  const [date_of_birthError, setDateOfBirthError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password2Error, setPassword2Error] = useState("");

  const [loading, setLoading] = useState(false);
  const [allCities, setAllCities] = useState([]);

  const { registerUser } = useContext(AuthContext);

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const closeHandler = () => {
    setVisible(false);
    history.push("/");
  };
  const closeHandler2 = () => {
    setVisible2(false);
    setRead(true);
    setReachedEnd(false);
  };

  const onSignupClick = (e) => {
    e.preventDefault();
    setLoading(true);
    setFirstNameError("");
    setLastNameError("");
    setOtherNameError("");
    setTelephoneError("");
    setEmailError("");
    setAddressError("");
    setCityError("");
    setStatesError("");
    setOccupationError("");
    setDateOfBirthError(null);
    setPasswordError("");
    setPassword2Error("");


    if (dayjs().diff(dayjs(date_of_birth), 'year') > 35.0) {
      setDateOfBirthError("You cannot be more than 35 years to register");
      setLoading(false);
      return;
    }

    const memberData = {
      first_name: first_name,
      last_name: last_name,
      other_name: other_name,
      telephone: telephone,
      email: email,
      address: address,
      city: city,
      states: states,
      occupation: occupation,
      date_of_birth: date_of_birth,
      password: password,
      password2: password2,
    };

    const errorData = {
      setFirstNameError,
      setLastNameError,
      setOtherNameError,
      setTelephoneError,
      setEmailError,
      setAddressError,
      setCityError,
      setStatesError,
      setOccupationError,
      setDateOfBirthError,
      setPasswordError,
      setPassword2Error,
      setLoading,
      setVisible,
    };

    registerUser(memberData, errorData);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function getStates() {
    const allStates = NaijaStates.states();
    return allStates.map((state) => (
      <MenuItem value={state} key={state}>
        {state}
      </MenuItem>
    ));
  }

  function renderCities() {
    return allCities.length ? (
      <TextField
        label="Local Government"
        fullWidth
        clearable
        required
        select
        value={city}
        variant="standard"
        onChange={(e) => setCity(e.target.value)}
        name="city"
        error={!!cityError}
        helperText={!!cityError ? cityError : ""}
      >
        {allCities.map((city) => (
          <MenuItem value={city} key={city}>
            {city}
          </MenuItem>
        ))}
      </TextField>
    ) : (
      <TextField
        id={"cityInput"}
        value={"Select a state"}
        select
        label="Local Government"
        fullWidth
        clearable
        required
        variant="standard"
        error={!!cityError}
        helperText={!!cityError ? cityError : ""}
      >
        <MenuItem value="Select a state">Select a state</MenuItem>
      </TextField>
    );
  }

  const getCities = (e) => {
    setAllCities(NaijaStates.lgas(e)["lgas"]);
    renderCities();
  };

  useEffect(() => {
    // send request with empty token
    localStorage.removeItem("authTokens");
    localStorage.removeItem("user");

    $("#nextui-modal").on("DOMSubtreeModified", function () {
      const el = $("#endTerms");
      if (el) {
        $("#terms").on("scroll", function () {
          setReachedEnd(isInViewport("#endTerms"));
        });
      }
    });
  });

  const form = (
    <>
      <Grid container spacing={0}>
        <Grid item xs={0} sm={5} className="w-100 side-container">
          <Box className="w-100 vh-100 d-flex flex-column">
            <img
              src={side}
              className="img-cover"
              width={"100%"}
              height={"100%"}
              alt="cooperation"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          mt={10}
          sx={{ pl: { xs: 5, lg: 15 }, pr: { xs: 5, lg: 10 } }}
        >
          <Typography component={"h5"} className="mt-3">
            OGSCOFED YOUTH COOPERATIVE
          </Typography>
          <Typography component={"h1"} fontWeight={"700"} fontSize={"3rem"}>
            Create
            <Typography
              fontWeight={"700"}
              fontSize={"3rem"}
              component={"span"}
              color="primary"
            >
              {" "}
              a&nbsp;
            </Typography>
            new account
            <Typography
              component={"span"}
              color="primary"
              size={"0.5em"}
              ml={"0.15em"}
              mr={"0.1em"}
            >
              <MdCircle />
            </Typography>
          </Typography>
          <Box
            component={"form"}
            noValidate
            autoComplete="new-password"
            onSubmit={onSignupClick}
          >
            <Grid container mt={7} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="First name"
                  fullWidth
                  required
                  clearable
                  variant="standard"
                  onChange={(e) => setFirstName(e.target.value)}
                  name="first_name"
                  error={!!first_nameError}
                  helperText={!!first_nameError ? first_nameError : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Last name"
                  fullWidth
                  required
                  clearable
                  variant="standard"
                  onChange={(e) => setLastName(e.target.value)}
                  name="last_name"
                  error={!!last_nameError}
                  helperText={!!last_nameError ? last_nameError : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Other name"
                  placeholder="Other name"
                  fullWidth
                  clearable
                  variant="standard"
                  onChange={(e) => setOtherName(e.target.value)}
                  name="other_name"
                  status={!!other_nameError}
                  helperText={!!other_nameError ? other_nameError : ""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email address"
                  fullWidth
                  clearable
                  required
                  variant="standard"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  error={!!emailError}
                  helperText={!!emailError ? emailError : ""}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="standard"
                  fullWidth
                  error={!!telephoneError}
                >
                  <InputLabel
                    shrink
                    htmlFor={"phoneNumberInput"}
                    sx={{ fontSize: "1.2rem" }}
                  >
                    Phone number
                  </InputLabel>
                  <Input
                    id={"phoneNumberInput"}
                    inputComponent={NumericFormatCustom}
                    onChange={(e) => setTelephone(`+234${e.target.value}`)}
                    name="telephone"
                    startAdornment={
                      <InputAdornment position="start">+234</InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {!!telephoneError ? telephoneError : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Address"
                  fullWidth
                  clearable
                  required
                  variant="standard"
                  onChange={(e) => setAddress(e.target.value)}
                  name="address"
                  error={!!addressError}
                  helperText={!!addressError ? addressError : ""}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id={"stateInput"}
                  value={states}
                  label="State"
                  onChange={(e) => {
                    setStates(e.target.value);
                    getCities(e.target.value);
                  }}
                  select
                  fullWidth
                  variant="standard"
                  error={!!statesError}
                  helperText={!!statesError ? statesError : ""}
                >
                  {getStates()}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                {renderCities()}
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Occupation"
                  fullWidth
                  required
                  onChange={(e) => setOccupation(e.target.value)}
                  variant="standard"
                  name="occupation"
                  error={!!occupationError}
                  helperText={!!occupationError ? occupationError : ""}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    value={date_of_birth}
                    onChange={(e) => {
                      setDateOfBirth(e.format("YYYY-MM-DD"));
                    }}
                    disableFuture
                    inputFormat="DD-MM-YYYY"
                    // maxDate={`${dayjs().format("YYYY")-18}-${dayjs().format("MM-DD")}`} // for mininimum age of 18 years
                    minDate={`${dayjs().format("YYYY")-35}-${dayjs().format("MM-DD")}`} // for maximum age of 35 years
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        name="date_of_birth"
                        error={!!date_of_birthError}
                        helperText={
                          !!date_of_birthError ? date_of_birthError : ""
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!passwordError}
                  required
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <MdVisibilityOff />
                          ) : (
                            <MdVisibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {!!passwordError ? passwordError : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!password2Error}
                  required
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                >
                  <InputLabel htmlFor="password2">
                    Password confirmation
                  </InputLabel>
                  <Input
                    id="password2"
                    type={showPassword2 ? "text" : "password"}
                    name="password2"
                    onChange={(e) => setPassword2(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword2 ? (
                            <MdVisibilityOff />
                          ) : (
                            <MdVisibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {!!password2Error ? password2Error : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  isSelected={selected}
                  onChange={(e) => {
                    // readTerms()
                    read ? setSelected(!selected) : setVisible2(true);
                  }}
                >
                  <span>
                    Yes, I consent to have read the&nbsp;
                    <Link
                      color={"secondary"}
                      // as={"button"}
                      onClick={(e) => {
                        e.preventDefault();
                        setVisible2(true);
                      }}
                      css={{ border: "none", px: 0 }}
                    >
                      Terms and Condition
                    </Link>
                    .
                  </span>
                </Checkbox>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  // fullWidth
                  type="submit"
                  // onPress={onSignupClick}
                  disabled={loading}
                  css={{ display: selected ? "block" : "none" }}
                >
                  {loading ? (
                    <Loading
                      textColor={"primary"}
                      color="currentColor"
                      type="points"
                      size="sm"
                    />
                  ) : (
                    "Create user"
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography mt={2}>
                  Already have an account?{" "}
                  <Link href="/account/login" color={"secondary"}>
                    Login
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Modal
            blur
            preventClose
            aria-labelledby={`terms-conditions-modal`}
            open={visible2}
            onClose={closeHandler2}
            scroll
            width="50%"
          >
            <Modal.Header className="d-flex flex-column text-start align-items-start">
              <Text id="modal-title text-success" b size={28}>
                Terms and Conditions
              </Text>
              <Text className="text-muted text-small">
                Last updated on:{" "}
                <span className="fw-bold">
                  {dayjs("07/08/2023").format("DD MMMM YYYY")}{" "}
                  {/* {dayjs("00:00").format("h:MM A")} */}
                </span>
              </Text>
            </Modal.Header>
            <Modal.Body id="terms">
              <Typography className="text-center fw-bold mt-5 fs-4">
                Welcome to OGSCOFED Youth Cooperative Multipurpose Society
                Limited!
              </Typography>
              <Typography className="fw-bold mt-2">
                The OGSCOFED Youth Cooperative Multipurpose Society Limited
                service, website and/or software application (collectively, the
                “Service”) is a platform that seeks to leverage the Cooperative
                model towards creating a transparent, open, and scalable
                community of young entrepreneurs, freelancers, start-ups, small
                business owners seeking sustainable business and financial
                growth. We are digital and innovative. The Service is operated
                by OGSCOFED Youth Cooperative Multipurpose Society Limited
                (“OYCMS”), subject to these Terms of Service (“Terms”).
              </Typography>
              <Typography className="mt-2">
                This is a contract. These Terms constitute a contract between
                you and OGSCOFED Youth Cooperative Multipurpose Society Limited.
                If you do not accept these Terms, please do not use the Service.
                By using any part of the Service you accept these Terms. If you
                are under eighteen (18) years of age, you may not use the
                Service.
              </Typography>
              <Typography className="mt-2">
                You must register an account with valid information. To use the
                Service, you must: (a) provide a valid mobile phone number and
                email; (b) agree that an account associated with such number be
                created; (c) accept the current Terms; and (d) submit such other
                and additional information as OYCMS may request. You agree to
                provide true, accurate and complete information about yourself
                as prompted by OYCMS during the account registration process. A
                unique LINK will be send to your email address to confirm your
                email address.
              </Typography>
              <Typography className="mt-2">
                Your privacy is important to us. OGSCOFED Youth Cooperative
                Multipurpose Society Limited takes the matters of protection and
                security of its users’ information very seriously. OGSCOFED
                Youth Cooperative Multipurpose Society Limited’s bye law
                governing the Service is attached to these Terms (“Bye Law”).
                The Bye Law is incorporated into these Terms by this reference.
                By using the Service, you agree to the use of your data in
                accordance with OGSCOFED Youth Cooperative Multipurpose Society
                Limited’s Bye Law. The Bye Law addresses only the information
                collected by OGSCOFED Youth Cooperative Multipurpose Society
                Limited website in providing the Service to you.{" "}
              </Typography>
              <Typography className="mt-2 fw-bold">
                After your registration, you are required to pay a sum of Two
                Thousand Naira (N2,000) as registration fees, provide your two
                (2) referees, upload a passport photograph and pay your
                development levy of Two Thousand, Four Hundred Naira (N2,400) as
                annual Subscription.
              </Typography>
              <Typography className="mt-2 fw-bold">
                Members shall be responsible for all the transaction charges.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">
                  You must not use the Service to violate any laws. You must not
                  use the Service to violate or infringe the rights of any other
                  person, including the rights of other users.{" "}
                </span>
                You must not breach any laws or regulations when using the
                Service or attempt to disrupt or interfere with the security or
                functionality of the Service. In the event that OGSCOFED Youth
                Cooperative Multipurpose Society Limited reasonably suspects
                that you are using the Services for illegal activities
                (particularly fraud, money laundering or other related
                illegalities), OGSCOFED Youth Cooperative Multipurpose Society
                Limited reserves the right to block your account immediately
                without liability. Such illegalities as mentioned above may be
                as a result of enacted laws, court judgments, rulings or
                pronouncements, or regulatory policies and guidelines to which
                OGSCOFED Youth Cooperative Multipurpose Society Limited is
                bound. If a breach is associated with your account, you agree
                that we have the right to apply restrictions to your account and
                report to the appropriate law enforcement agencies.
              </Typography>
              <Typography className="mt-2">
                OGSCOFED Youth Cooperative Multipurpose Society Limited may
                contact you regarding your account or the Service. You expressly
                agree that, as part of the Service, you may, from time to time,
                receive communications from OGSCOFED Youth Cooperative
                Multipurpose Society Limited via email, instant message,
                telephone, text message (SMS) or other means.
              </Typography>
              <Typography className="mt-2">
                OGSCOFED Youth Cooperative Multipurpose Society Limited may
                discontinue the Service. OGSCOFED Youth Cooperative Multipurpose
                Society Limited may in furtherance of regulatory, time-sensitive
                and security-related purposes terminate your access to the
                Service or discontinue providing the Service or any part of the
                Service, with due notice to you [or without notice where the
                suspension or termination is expedient to forestall, curb or
                extinguish some ongoing fraud, industry-wide compromise or an
                ongoing financial crime-related investigation]. Rest assured
                that we will provide as much notice as the circumstance allows,
                and restore the Service at the earliest convenience. You agree
                that in the event of the foregoing, OGSCOFED Youth Cooperative
                Multipurpose Society Limited will not be responsible or liable
                to you or any third party.
              </Typography>
              <Typography className="mt-2">
                The Service is provided without any warranties or guarantees.
                The Service is provided “as is“ without warranty of any kind.
                OGSCOFED Youth Cooperative Multipurpose Society Limited and its
                suppliers and affiliates disclaim all warranties with regard to
                the service, including all implied warranties, fitness for a
                particular purpose, title, and non-infringement. If you are
                dissatisfied with any portion of the service, or with any of
                these terms, your sole and exclusive remedy is to discontinue
                using the service.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">
                  OGSCOFED Youth Cooperative Multipurpose Society Limited is not
                  liable for any damages you may incur as a result of using the
                  Services.
                </span>
                In no event shall OGSCOFED Youth Cooperative Multipurpose
                Society Limited or its suppliers be liable for any direct,
                indirect, punitive, incidental, special, consequential damages,
                or any damages whatsoever arising out of, or in any way
                connected with the use or performance of the service, with the
                delay or inability to use the service, the provision of (or
                failure to provide services), or otherwise arising out of the
                use of the service, whether based on contract, tort, negligence,
                strict liability, or otherwise, even if OGSCOFED Youth
                Cooperative Multipurpose Society Limited or any of its suppliers
                have been advised of the possibility of such damages.
              </Typography>
              <Typography className="mt-2">
                You agree to arbitrate any disputes. This Agreement is subject
                to, and shall be governed by, and construed in accordance with
                the laws of Nigeria, without reference to the principles of
                conflict of laws thereof. Any matters arising concerning the
                interpretation, validity or implementation of this Agreement not
                solved by mutual agreement between the Parties shall be
                submitted to arbitration in the English language before a sole
                arbitrator to take place in the Office of the Director of
                Cooperative Services in Ogun State, Nigeria as the seat of the
                arbitration. The arbitration shall be conducted pursuant to the
                Rules of Arbitration of the Director of Cooperative Services in
                Ogun State. The arbitral decision shall be final and binding on
                the Parties and may be made an order of court. The Parties
                unconditionally consent and submit to the jurisdiction of the
                High Court of Ogun State, Nigeria for such purpose. Nothing in
                this Agreement will be deemed as preventing OGSCOFED Youth
                Cooperative Multipurpose Society Limited from seeking injunctive
                relief (or any other provisional remedy) from any court having
                jurisdiction over the Parties and the subject matter of the
                dispute as is necessary to protect OGSCOFED Youth Cooperative
                Multipurpose Society Limited’s name, proprietary information,
                trade secrets, know-how, or any other intellectual property
                rights.
              </Typography>
              <Typography className="mt-2">
                OGSCOFED Youth Cooperative Multipurpose Society Limited may
                modify these Terms. These Terms and related policies (including
                but not limited to the Bye Law) may be modified by OGSCOFED
                Youth Cooperative Multipurpose Society Limited at any time in
                the future. Where this happens, we will communicate the changes
                to you. By continuing to use the Service, you agree to be bound
                by the latest version of these Terms. It is your responsibility
                to familiarize yourself with the communicated changes.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">Alteration.</span>No alteration,
                variation or agreed cancellation of this agreement, and the
                Privacy Policy, shall be of any effect unless so directed by us.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">Whole Agreement.</span>This Agreement
                constitutes the whole agreement between the parties in regard to
                the subject matter hereof and no warranties or representations
                of any nature whatsoever other than set out in this agreement
                have been given by any of the parties.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">Waiver/Relaxation.</span>No relaxation
                or indulgence which OGSCOFED Youth Cooperative Multipurpose
                Society Limited may show to you shall in any way prejudice or be
                deemed to be a waiver of its rights hereunder.
              </Typography>
              <Typography className="mt-2">
                Survival. Each and every provision of this Agreement (excluding
                only those provisions which are essential at law for a valid and
                binding Agreement to be constituted) shall be deemed to be
                separate and severable from the remaining provisions of this
                Agreement. If any of the provisions of this Agreement (excluding
                only those provisions which are essential at law for a valid and
                binding Agreement to be constituted) is found by any court of
                competent jurisdiction to be invalid and/or unenforceable then,
                notwithstanding such invalidity and/or unenforceability, the
                remaining provisions of this Agreement shall be (and remain) of
                full force and effect.
              </Typography>
              <Typography className="mt-5 fw-bold fs-4">
                TERMS AND CONDITIONS FOR SHARES/SAVING
              </Typography>
              <Typography className="mt-2">
                Effective Date: July, 2023
              </Typography>
              <Typography className="mt-2 fst-italic">
                Please read carefully before using this service.
              </Typography>
              <Typography className="mt-2">
                The Shares/Saving service, website (the “Service”) provides you
                information on financing options such as loans and funding. And
                also resources on online financial management tools through
                savings and investment in accordance with our products under
                these Shares/Saving Products Terms and Conditions (the “Terms”).
              </Typography>
              <Typography className="mt-2 fw-bold">
                1. THIS IS A CONTRACT
              </Typography>
              <Typography className="mt-2">
                These Terms constitute a contract between you and Shares/Saving
                Products. Please do not use the Service if you do not accept
                these Terms. By using any part of the Service you accept these
                Terms. If you are under eighteen (18) years of age, you may not
                use the Service.
              </Typography>
              <Typography className="mt-2 fw-bold">
                2. ACCOUNT REGISTRATION
              </Typography>
              <Typography className="mt-2">
                You must register an account with valid information. To use the
                Service, you must: (a) provide a valid mobile phone number and
                email address; (b) agree to the creation of an account
                associated with such number and email address; (c) accept the
                present Terms; and (d) submit such other and additional
                information as Shares/Saving Products may request during
                registration. You agree to provide true, accurate and complete
                information about yourself as prompted by Shares/Saving Products
                during the account registration process. You will be given the
                option of receiving a unique LINK for purposes of confirming
                your account. You are responsible for keeping your PASSWORD
                secure. Your personal information is highly protected in
                accordance with our Privacy Policy/Bye Law published and updated
                on our platform. The Shares/Saving Products shall only be made
                available to you once our Know Your member process has been
                complied with by you and your identification has been verified.
              </Typography>
              <Typography className="mt-2 fw-bold">
                3. SERVICES DISCRIPTION
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">Various sub-products: </span>Under our
                Share/Saving Products, we may provide various kinds of
                sub-products for your choice to deposit amount and earning
                interests, including Extra/Special Savings. Our minimum Shares
                capital is N24,000
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">Withdrawal:</span>You are entitled to
                withdraw your Extra/Special Savings deposited money by giving us
                14 working days’ notice in accordance with our withdrawal
                policies on our Platform. But Shares cannot be withdraw unless
                you want to quit the Cooperative Society. To withdraw your
                Shares, a 6 months’ notice must be given.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">Transfer:</span>You cannot transfer
                your Shares/Saving Products balance to any member, your own bank
                accounts, others’ bank accounts or any account.
              </Typography>
              <Typography className="mt-2 fw-bold">
                Member shall be responsible for all bank transaction charges.
              </Typography>
              <Typography className="mt-2 fw-bold">4. Loan:</Typography>
              <Typography className="mt-2">
                Loans may be granted to members only for necessary or productive
                purposes.
              </Typography>
              <Typography className="mt-2 fw-bold">
                4.1 Conditions Governing Loans:
              </Typography>
              <Typography className="mt-2">
                (a). Loans shall be grants to members on the security of their
                money or on such basis as the General Meeting may decide,{" "}
                <span className="fw-bold">
                  but no member can claim a loan as a right.
                </span>
              </Typography>
              <Typography className="mt-2">
                (b).{" "}
                <span className="fw-bold">
                  No loan shall be granted to any member whose installments of
                  shares/savings/development levy products are in arrears and
                  has not been regular in attending at meetings.
                </span>
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.2. Application for Loans: </span>
                This shall be made through Online Platform and disposed by the
                Committee.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.3. Loans to Member: </span>Except in
                the first year of a society. No loans shall be made to a member
                within a month of his admission by the Committee except in the
                case of the transfer of an existing loan of a past member to his
                Nominee or other successor in interest who becomes a member of
                the Society. No loan shall be granted.
              </Typography>
              <Typography className="mt-2">
                (a). Unless approved by a majority of members of the Committee
                who are present at the meeting at which the application is
                considered.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.4. Maximum Credit Limit: </span>The
                Maximum Credit Limit of each applicant for a loan shall be times
                two and bearing in mind the availability of funds, the principle
                of making credit available to as many members as are qualified
                and the securities offered.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">
                  4.5. Interest on Loan to Members:{" "}
                </span>
                Interest on loans to members shall be charged at a rate of 15
                percent per annual. Subject to be review.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.6. Bonds and Sureties: </span>Every
                borrower shall execute a bond and shall furnish two sureties. No
                extension of the period of repayment shall be granted without
                the consent of the Sureties. Sureties must be members of the
                Society.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.7. Duties of Sureties: </span>A
                Surety must be a reputable member of the Society and shall
                personally guarantee:
              </Typography>
              <Typography className="mt-2">
                (a). repayment of the loan if the borrower defaults (i.e
                sureties fund (share/savings) will be used to repay the loan
                back);
              </Typography>
              <Typography className="mt-2">
                (b). the correctness of the particulars on which the value of
                the borrowers securities is based;
              </Typography>
              <Typography className="mt-2">
                (c). that the loan is used for the purpose stated by the
                borrower in his application. Misuse of the loan shall be
                reported by the surety to the Committee.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.8. Repayment: </span>Both loan and
                interest are repayable not later than Ten (10) months after the
                receipt of the loans, if a borrower is unable to repay
                punctually, the Committee may for satisfactory and recorded
                reasons grant extension not exceeding one year.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.9. Overdue Loans: </span>When a loan
                is not paid on the date due,{" "}
                <span className="fw-bold">
                  the loan account shall at once be charged penal interest from
                  the date of closing the loan account to the date of recovery
                  and steps shall be taken promptly, firstly by deducting part
                  of it from any sum due to the member{" "}
                </span>
                and thereafter, in any lawful manner that the Committee may deem
                suitable.
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">4.10. Recall of Loans: </span>All
                loans shall be issued subject to the right of the Society to
                call for immediate repayment:
              </Typography>
              <Typography className="mt-2">
                (a). if any misapplication is proved;
              </Typography>
              <Typography className="mt-2">
                (b). if the borrower gives an indication of resignation
              </Typography>
              <Typography className="mt-2">
                <span className="fw-bold">
                  4.11. Appropriation of Debt Payments:{" "}
                </span>
                When a Member from whom money is due pays any sum of the
                society. It shall be appropriated in the following order:
              </Typography>
              <Typography className="mt-2">
                Firstly, to share payments, fines and other miscellaneous
                charges due to him; Secondly, to interest on loan and; thirdly,
                to loan principal.
              </Typography>
              <Typography className="mt-2 fw-bold">5. DIVIDEND</Typography>
              <Typography className="mt-2">
                Any dividend shall only be payable on funds that have remained
                in your Shares/Saving Products Wallet as at the official closing
                period of account, i.e. 31st of December of the financial year.
              </Typography>
              <Typography className="mt-2">
                You acknowledge and agree that Shares/Saving Products shall have
                the right to amend the dividend rate applicable to the
                Shares/Saving Products and you hereby indemnify and waive
                any/all claims of any nature against Shares/Saving Products for
                dividend on your Shares/Saving Products.
              </Typography>
              <Typography className="mt-2">
                We confirm that the dividend we offer to you shall be in
                compliance with applicable laws and regulations. In case that
                any change for the laws and regulations results in the decrease
                of your dividend received, we are not obligated to compensate
                for it.
              </Typography>
              <Typography className="mt-2 fw-bold">
                Any member that owed OGSCOFED Youth Cooperative Multipurpose
                Society Limited any dues, it shall be deducted from the member’s
                dividend before it is paid to him/her.
              </Typography>
              <Typography className="mt-2 fw-bold">6. ANTI-FRAUD</Typography>
              <Typography className="mt-2">
                You agree and acknowledge that where the funds in your
                Shares/Saving Products wallet have been flagged as or are
                suspected of being fraudulent in nature, Shares/Saving Products
                may immediately suspend your account and retain the funds
                pending an investigation.
              </Typography>
              <Typography className="mt-2 fw-bold">7. NO WARRANTIES</Typography>
              <Typography className="mt-2">
                The Service is provided without any warranties or guarantees.
                THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND.
                Saving Products AND ITS SUPPLIERS AND AFFILIATES DISCLAIM ALL
                WARRANTIES WITH REGARD TO THE SERVICE, INCLUDING ALL IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE, AND NON-INFRINGEMENT. IF YOU ARE DISSATISFIED WITH ANY
                PORTION OF THE SERVICE, OR WITH ANY OF THESE TERMS, YOUR SOLE
                AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE.
              </Typography>
              <Typography className="mt-2 fw-bold">
                8. NOT LIABLE FOR DAMAGES
              </Typography>
              <Typography className="mt-2">
                Shares/Saving Products is not liable for any damages you may
                incur as a result of using the Services. IN NO EVENT SHALL BE
                LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER ARISING OUT OF
                OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE
                SERVICE, WITH THE DELAY OR INABILITY TO USE THE SERVICE, THE
                PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR OTHERWISE
                ARISING OUT OF THE USE OF THE SERVICE, WHETHER BASED ON
                CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, ANY OF ITS
                SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </Typography>
              <Typography className="mt-2 fw-bold">
                9. DISPUTES AND ARBITRATION
              </Typography>
              <Typography className="mt-2">
                You agree to arbitrate any disputes. This Agreement is subject
                to, and shall be governed by, and construed in accordance with
                the laws of Nigeria, without reference to the principles of
                conflict of laws thereof. Any matters arising concerning the
                interpretation, validity or implementation of this Agreement not
                solved by mutual agreement between the Parties shall be
                submitted to arbitration in the English language before a sole
                arbitrator to take place in Ogun State, Nigeria as the seat of
                the arbitration. The arbitration shall be conducted pursuant to
                the Rules of Arbitration of the Director of Cooperative Services
                in Ogun State. The arbitral decision shall be final and binding
                on the Parties and may be made an order of Director of
                Cooperative Services.
              </Typography>
              <Typography className="mt-2 fw-bold">10. ENFORCEMENT</Typography>
              <Typography className="mt-2">
                If arbitration, a court of competent jurisdiction or other
                competent authority finds any part of this Agreement invalid,
                unlawful or unenforceable, then such part shall be severed from
                the remainder of this Agreement which will continue to be valid
                and enforceable to the fullest extent permitted by law.
              </Typography>
              <div id="endTerms" className="mt-2"></div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                auto
                // color="secondary"
                onClick={closeHandler2}
                css={{ display: reachedEnd ? "block" : "none" }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </Grid>
      </Grid>
    </>
  );

  return (
    <main>
      <NavbarComp />
      <Box>
        {form}
        <Modal
          closeButton
          preventClose
          aria-labelledby="modal-title"
          open={visible}
          onClose={closeHandler}
        >
          <Modal.Header>
            <Text id="modal-title" b size={20}>
              Account created successfully
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Nrow justify="space-around">
              <Text size={120} color="$primary">
                <IoShieldCheckmarkSharp />
              </Text>
            </Nrow>
            <Nrow
              justify="space-around"
              css={{ textAlign: "center", marginTop: "-3em" }}
            >
              <Text size={18}>
                Email verification sent, check your spam folder if you can't
                find it.
              </Text>
            </Nrow>
          </Modal.Body>
          <Modal.Footer>
            <Button auto flat color="error" onPress={closeHandler}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Box>
    </main>
  );
}

export default Signup;
