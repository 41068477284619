import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import PerfectScrollbar from "perfect-scrollbar";

import AuthContext from "../../context/AuthContext";
import {
  SideNav,
  TopNav,
  DashboardFooter,
} from "../../Components/Navs/dashboard";

import { Container, Row, Col } from "reactstrap";
import { Loading, Modal, Button, Text } from "@nextui-org/react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { toast } from "react-toastify";
import * as bootstrap from "bootstrap";


const steps = ["Referee 1", "Referee 2"];

const Referees = () => {
  window.bootstrap = bootstrap;
  const { user } = useContext(AuthContext);
  const [referees, setReferees] = useState({});

  const [name1, setName1] = useState("");
  const [society1, setSociety1] = useState("");
  const [position1, setPosition1] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [email1, setEmail1] = useState("");
  const [name2, setName2] = useState("");
  const [society2, setSociety2] = useState("");
  const [position2, setPosition2] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [email2, setEmail2] = useState("");

  const [name1Error, setName1Error] = useState("");
  const [society1Error, setSociety1Error] = useState("");
  const [position1Error, setPosition1Error] = useState("");
  const [telephone1Error, setTelephone1Error] = useState("");
  const [email1Error, setEmail1Error] = useState("");
  const [name2Error, setName2Error] = useState("");
  const [society2Error, setSociety2Error] = useState("");
  const [position2Error, setPosition2Error] = useState("");
  const [telephone2Error, setTelephone2Error] = useState("");
  const [email2Error, setEmail2Error] = useState("");

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [refereeStatus, setRefereeStatus] = useState(false);

  const [regData, setRegData] = useState(true);

  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setName1Error("");
    setEmail1Error("");
    setTelephone1Error("");
    setSociety1Error("");
    setPosition1Error("");
    setName2Error("");
    setEmail2Error("");
    setTelephone2Error("");
    setSociety2Error("");
    setPosition2Error("");

    const refereeData = {
      referee_one_name: name1,
      referee_one_tel: `+234${telephone1}`,
      referee_one_email: email1,
      referee_one_society: society1,
      referee_one_position: position1,
      referee_two_name: name2,
      referee_two_tel: `+234${telephone2}`,
      referee_two_email: email2,
      referee_two_society: society2,
      referee_two_position: position2,
    };

    try {
      await axios
        .post(
          `https://youthapi.ogscofed.coop/account/${user.user_id}/referee/`,
          refereeData
        )
        .then((res) => {
          setLoading(false);
          window.location.reload(true);
          toast.success("Referees added successfully");
        });
    } catch (error) {
      if (error.response.data.hasOwnProperty("detail")) {
        toast.error(error.response.data["detail"].toString());
      }
      if (error.response.data.hasOwnProperty("referee_one_name")) {
        setName1Error(error.response.data["referee_one_name"]);
      }
      if (error.response.data.hasOwnProperty("referee_one_society")) {
        setSociety1Error(error.response.data["referee_one_society"]);
      }
      if (error.response.data.hasOwnProperty("referee_one_position")) {
        setPosition1Error(error.response.data["referee_one_position"]);
      }
      if (error.response.data.hasOwnProperty("referee_one_email")) {
        setEmail1Error(error.response.data["referee_one_email"]);
      }
      if (error.response.data.hasOwnProperty("referee_one_tel")) {
        setTelephone1Error(error.response.data["referee_one_tel"]);
      }
      if (error.response.data.hasOwnProperty("referee_two_name")) {
        setName2Error(error.response.data["referee_two_name"]);
      }
      if (error.response.data.hasOwnProperty("referee_two_society")) {
        setSociety2Error(error.response.data["referee_two_society"]);
      }
      if (error.response.data.hasOwnProperty("referee_two_position")) {
        setPosition2Error(error.response.data["referee_two_position"]);
      }
      if (error.response.data.hasOwnProperty("referee_two_email")) {
        setEmail2Error(error.response.data["referee_two_email"]);
      }
      if (error.response.data.hasOwnProperty("referee_two_tel")) {
        setTelephone2Error(error.response.data["referee_two_tel"]);
      }
      toast.error(
        "You have some errors in your form, check them and try again."
      );
      setLoading(false);
    }
  };

  const closeHandler = () => {
    setVisible(false);
  };

  const getReferees = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/${user.user_id}/referee/`)
        .then((res) => {
          setReferees(res.data);
          setRefereeStatus(true);
        });
    } catch (error) {
      setRefereeStatus(false);
      // toast.error("Unable to get Referees, refresh or try again!");
    }
  };

  const getRegistrationData = async () => {
    try {
      await axios
        .get(`https://youthapi.ogscofed.coop/account/register/payment/${user.user_id}/`)
        .then((res) => {
          setRegData(false);
          toast.info(res.data["message"].toString());
        });
    } catch (error) {
      setRegData(true);
      toast.info(error.reponse.data["error"].toString());
    }
  };

  useEffect(() => {

    if (document.getElementsByClassName("main-content")[0]) {
      new PerfectScrollbar(".main-content");
    }

    if (document.getElementsByClassName("navbar-collapse")[0]) {
      new PerfectScrollbar(
        ".navbar:not(.navbar-expand-lg) .navbar-collapse"
      );
    }

    getReferees();
    getRegistrationData();
  }, []);

  return (
    <>
      {user ? (
        refereeStatus ? (
          <div>
            <SideNav active="referees" />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
              {/* <!-- Navbar --> */}
              <TopNav />
              {/* <!-- End Navbar --> */}
              <div className="col-12 mt-4">
                <div className="card mx-3">
                  <div className="card-header pb-0 px-3">
                    <h6 className="mb-0">Referees Information</h6>
                  </div>
                  <div className="card-body pt-4 p-3">
                    <ul className="list-group">
                      <li className="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                        <div className="d-flex flex-column">
                          <h6 className="mb-3 text-sm text-capitalize">
                            {referees.referee_one_name}
                          </h6>
                          <span className="mb-2 text-xs">
                            Email Address:{" "}
                            <span className="text-dark ms-sm-2 font-weight-bold">
                              {referees.referee_one_email}
                            </span>
                          </span>
                          <span className="mb-2 text-xs">
                            Phone Number:{" "}
                            <span className="text-dark ms-sm-2 font-weight-bold">
                              {referees.referee_one_tel}
                            </span>
                          </span>
                          <span className="mb-2 text-xs">
                            Society Name:{" "}
                            <span className="text-dark font-weight-bold ms-sm-2">
                              {referees.referee_one_society}
                            </span>
                          </span>
                          <span className="text-xs">
                            Society Position:{" "}
                            <span className="text-dark font-weight-bold ms-sm-2">
                              {referees.referee_one_position}
                            </span>
                          </span>
                        </div>
                        <div className="ms-auto text-end">
                          {/* <a className="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;"><i className="material-icons text-sm me-2">delete</i>Delete</a>
                          <a className="btn btn-link text-dark px-3 mb-0" href="javascript:;"><i className="material-icons text-sm me-2">edit</i>Edit</a> */}
                        </div>
                      </li>
                      <li className="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg">
                        <div className="d-flex flex-column">
                          <h6 className="mb-3 text-sm text-capitalize">
                            {referees.referee_two_name}
                          </h6>
                          <span className="mb-2 text-xs">
                            Email Address:{" "}
                            <span className="text-dark ms-sm-2 font-weight-bold">
                              {referees.referee_two_email}
                            </span>
                          </span>
                          <span className="mb-2 text-xs">
                            Phone Number:{" "}
                            <span className="text-dark ms-sm-2 font-weight-bold">
                              {referees.referee_two_tel}
                            </span>
                          </span>
                          <span className="mb-2 text-xs">
                            Society Name:{" "}
                            <span className="text-dark font-weight-bold ms-sm-2">
                              {referees.referee_two_society}
                            </span>
                          </span>
                          <span className="text-xs">
                            Society Position:{" "}
                            <span className="text-dark font-weight-bold ms-sm-2">
                              {referees.referee_two_position}
                            </span>
                          </span>
                        </div>
                        <div className="ms-auto text-end">
                          {/* <a className="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;"><i className="material-icons text-sm me-2">delete</i>Delete</a>
                          <a className="btn btn-link text-dark px-3 mb-0" href="javascript:;"><i className="material-icons text-sm me-2">edit</i>Edit</a> */}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <DashboardFooter />
            </main>
          </div>
        ) : (
          <div>
            <SideNav active="referees" />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
              <TopNav />
              {/* No referee alert */}
              {Object.keys(referees).length >= 1 ? (
                <></>
              ) : (
                <div
                  class="alert alert-primary alert-dismissible text-white m-3 text-center"
                  role="alert"
                >
                  <span class="text-sm">
                    You do not have your referees set, usage might be reduced.
                    Please use the button below to add referees.
                  </span>
                  <button
                    type="button"
                    class="btn-close text-lg py-3 opacity-10"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <div className="col-12 mt-4">
                <div className="card mx-3">
                  <div className="card-header pb-0 px-3">
                    <div className="row">
                      <h6 className="mb-0">
                        Referees Information
                        <span className="ms-auto">
                          {regData ? (
                            <Button
                              className="btn btn-link text-primary px-3 mb-0"
                              role="button"
                              onClick={handler}
                              disabled={!regData}
                            >
                              <i className="material-icons text-sm me-2">add</i>
                              Add Referee
                            </Button>
                          ) : (
                            <></>
                          )}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                closeButton
                // blur
                preventClose
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
                width="50%"
                className="ref-modal"
              >
                <Modal.Header>
                  <Text id="modal-title" size={18}>
                    <Text b size={18}>
                      Add Referees
                    </Text>
                  </Text>
                </Modal.Header>
                <Modal.Body>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          All referees completed - you&apos;re finished. Click
                          on button to submit.
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Row>
                          {activeStep === 0 ? (
                            <Col css={{ marginTop: "$10" }} className="fields">
                              <Container fluid className="px-0">
                                <Row>
                                  <Row>
                                    <Col className="mb-4">
                                      <TextField
                                        label="Full name"
                                        fullWidth
                                        required
                                        clearable
                                        variant="standard"
                                        onChange={(e) =>
                                          setName1(e.target.value)
                                        }
                                        name="Name"
                                        value={name1}
                                        error={!!name1Error}
                                        helperText={
                                          !!name1Error ? name1Error : ""
                                        }
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs={12} md={7} className="mb-4">
                                      <TextField
                                        label="Email address"
                                        fullWidth
                                        clearable
                                        variant="standard"
                                        onChange={(e) =>
                                          setEmail1(e.target.value)
                                        }
                                        name="email"
                                        value={email1}
                                        type="email"
                                        error={!!email1Error}
                                        helperText={
                                          !!email1Error ? email1Error : ""
                                        }
                                      />
                                    </Col>

                                    <Col xs={12} md={5} className="mb-4">
                                      <TextField
                                        label="Telephone"
                                        placeholder="Ex: 8033333333"
                                        fullWidth
                                        clearable
                                        required
                                        variant="standard"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              +234
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e) =>
                                          setTelephone1(e.target.value)
                                        }
                                        name="telephone"
                                        value={telephone1}
                                        type="tel"
                                        error={!!telephone1Error}
                                        helperText={
                                          !!telephone1Error
                                            ? telephone1Error
                                            : ""
                                        }
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={7}
                                      className="mb-4"
                                    >
                                      <TextField
                                        label="Society"
                                        fullWidth
                                        clearable
                                        required
                                        variant="standard"
                                        onChange={(e) =>
                                          setSociety1(e.target.value)
                                        }
                                        name="society"
                                        value={society1}
                                        error={!!society1Error}
                                        helperText={
                                          !!society1Error ? society1Error : ""
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={5}
                                      className="mb-4"
                                    >
                                      <TextField
                                        label="Position"
                                        fullWidth
                                        clearable
                                        required
                                        variant="standard"
                                        onChange={(e) =>
                                          setPosition1(e.target.value)
                                        }
                                        name="position"
                                        value={position1}
                                        error={!!position1Error}
                                        helperText={
                                          !!position1Error ? position1Error : ""
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Row>
                              </Container>
                            </Col>
                          ) : (
                            <Col css={{ marginTop: "$10" }} className="fields">
                              <Container fluid className="px-0">
                                <Row>
                                  <Row>
                                    <Col className="mb-4">
                                      <TextField
                                        label="Full name"
                                        fullWidth
                                        required
                                        clearable
                                        variant="standard"
                                        onChange={(e) =>
                                          setName2(e.target.value)
                                        }
                                        name="Name"
                                        value={name2}
                                        error={!!name2Error}
                                        helperText={
                                          !!name2Error ? name2Error : ""
                                        }
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs={12} md={7} className="mb-4">
                                      <TextField
                                        label="Email address"
                                        fullWidth
                                        clearable
                                        required
                                        variant="standard"
                                        onChange={(e) =>
                                          setEmail2(e.target.value)
                                        }
                                        name="email"
                                        value={email2}
                                        type="email"
                                        error={!!email2Error}
                                        helperText={
                                          !!email2Error ? email2Error : ""
                                        }
                                      />
                                    </Col>

                                    <Col xs={12} md={5} className="mb-4">
                                      <TextField
                                        label="Telephone"
                                        placeholder="Ex: 8033333333"
                                        fullWidth
                                        clearable
                                        required
                                        variant="standard"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              +234
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e) =>
                                          setTelephone2(e.target.value)
                                        }
                                        name="telephone"
                                        value={telephone2}
                                        type="tel"
                                        error={!!telephone2Error}
                                        helperText={
                                          !!telephone2Error
                                            ? telephone2Error
                                            : ""
                                        }
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={7}
                                      className="mb-4"
                                    >
                                      <TextField
                                        label="Society"
                                        fullWidth
                                        clearable
                                        required
                                        variant="standard"
                                        onChange={(e) =>
                                          setSociety2(e.target.value)
                                        }
                                        name="Society"
                                        value={society2}
                                        error={!!society2Error}
                                        helperText={
                                          !!society2Error ? society2Error : ""
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={5}
                                      className="mb-4"
                                    >
                                      <TextField
                                        label="Position"
                                        fullWidth
                                        clearable
                                        required
                                        variant="standard"
                                        onChange={(e) =>
                                          setPosition2(e.target.value)
                                        }
                                        name="position"
                                        value={position2}
                                        error={!!position2Error}
                                        helperText={
                                          !!position2Error ? position1Error : ""
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Row>
                              </Container>
                            </Col>
                          )}
                        </Row>
                      </React.Fragment>
                    )}
                  </Box>
                </Modal.Body>
                <Modal.Footer>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        size={"sm"}
                        // sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button
                        size={"sm"}
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <Loading
                            textColor={"primary"}
                            color="currentColor"
                            type="points"
                            size="sm"
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        size={"sm"}
                        // sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button onClick={handleNext} size={"sm"}>
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </React.Fragment>
                  )}
                </Modal.Footer>
              </Modal>
            <DashboardFooter />
            </main>
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default Referees;
