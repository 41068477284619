import { Container } from "reactstrap";
import NavbarComp from "../Components/Navs/topNav";
import Footer from "../Components/Navs/footer";

import side from "../static/images/side.jpg";
import { Grid, Typography } from "@mui/material";

const About = () => {
  return (
    <>
      <NavbarComp />
      <div
        className="page-header min-height-300 mt-4 justify-content-center flex-column"
        style={{ backgroundImage: `url('${side}')` }}
      >
        <Typography
          variant="h1"
          className="z-index-1 text-white"
          sx={{ fontSize: { xs: "5em", md: "6em" } }}
        >
          About Us
        </Typography>
        <Typography variant="h6" className="z-index-1 text-white">
          Telling our inspiring story
        </Typography>
        <span className="mask bg-gradient-primary opacity-8"></span>
      </div>
      <Container className="d-flex flex-column align-items-center mt-8 w-75">
        <Typography variant="h3" className="w-100 text-primary">
          Who We Are
        </Typography>
        <Typography className="fs-5">
          The OGSCOFED Youth Cooperative Multipurpose Society Limited is a
          component of Ogun State Cooperative Federation Limited (OGSCOFED) - an
          independent Non-Governmental Organisation (NGO) established in
          February, 1976 in line with International and National best practices
          as an umbrella body to unite, represent and serve Co-operative
          Societies in Ogun State. Since its inception, it has become the voice
          of the Cooperative Movement in Ogun State as it, unites and
          represents, Branches, Apexes, Unions, Societies and individual
          cooperative organisations.
        </Typography>
        <Typography className="fs-5 mt-2">
          OGSCOFED is organised with Ten (10) Administrative Branches, namely:
          Abeokuta Branch I, Abeokuta Branch II, Ijebu Central Branch, Remo
          Branch I, Ijebu North Branch, Abeokuta Central Branch, Remo Branch II,
          Ewekoro/Ifo/Ado Odo/Ota Branch, Yewa South Branch and Yewa North
          Branch and Four (4) Zones, namely: Ogun Central Salary Earners, Ijebu
          Zone Salary Earners, Remo Zone Salary Earners and Yewa Zone Salary
          Earners across Ogun State (Banking, Agriculture, Fisheries, Insurance,
          Health, Housing, Consumer Cooperatives, and Worker Cooperatives) and
          various thematic committees.
        </Typography>
        <Typography className="fs-5 mt-2">
          One of such thematic committees is the OGSCOFED Youth Cooperative
          Multipurpose Society Limited, which was set up in 2014 by OGSCOFED as
          an advisory, assistance and representation body for the youths within
          OGSCOFED.
        </Typography>
        <Typography className="fs-5 mt-2">
          Being part of a larger community of experts in various fields gives
          you the great network and leverage you deserve to get to your desired
          goal and achieve your dreams in life. Here is the chance you’ve been
          waiting for…{" "}
          <a className="text-secondary fw-bold" href="/account/register">
            Join Us Now!
          </a>
        </Typography>
        <Typography className="fs-5 mt-2">
          OGSCOFED Youth Cooperative Multipurpose Society Limited is dully
          registered with the appropriate government regulatory body in charge
          of cooperatives and has a Management Committee/Team with the
          prerequisite capacity to manage the Platform Co-ops sustainably.
        </Typography>
      </Container>
      <Container className="d-flex flex-column align-items-center mt-4 w-75">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" className="w-100 text-primary">
              What We Offer
            </Typography>
            <Typography className="fs-5">
              At OGSCOFED Youth Cooperative platform, you can choose a savings
              product that meets your need. Our Savings Products are designed to
              help instill the culture of Thriftiness & inculcate Saving habit
              into our members. Money saved with the cooperative grows and
              attracts annual interest yield (Dividends)
            </Typography>
            <Typography className="fs-5 mt-2">
              Our Loan Products are tailored towards expanding members
              businesses, improving their lives economically and meeting all
              other socio-cultural needs & aspirations. Get your desired Loan
              (s) with No Collateral, No Stress and No Delay. Loans are only
              available to our members that are qualified.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" className="w-100 text-primary">
              What You Get
            </Typography>
            <Typography className="fs-5">
              Our Co-ops is fully tested, background checked, license validated
              and insured with a 100% satisfaction guarantee. Our management
              team is made up of a diverse but healthy mix of professionals with
              combined demonstrated capacity of over 50 years in cooperative,
              micro-financing, ICT, financial literacy and human resource
              management services.
            </Typography>
            <Typography className="fs-5 mt-2">
              As members, this Cooperative platform allows members to invest in
              verified investment opportunities, as well as, acquire shares of
              the cooperative from time to time when the share window is opened.
              Ownership of the share shall entitle a member to the privileges
              and obligations of membership.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className="d-flex flex-column align-items-center mt-4 w-75">
        <Typography variant="h3" className="w-100 text-primary">
          Vision and Mission Statements
        </Typography>
        <Grid container className="mt-1">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" className="w-100 text-primary">
              Vision
            </Typography>
            <Typography className="fs-5">
              To foster a sense of belonging and make the youth committee and
              network a reference for Cooperative Network looking for youth.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" className="w-100 text-primary">
              Mission
            </Typography>
            <Typography className="fs-5">
              To strengthen the position of youth in Cooperative Movement in
              general and work for the presence and involvement of youth in Ogun
              State Cooperative and in the structure of Ogun State Cooperative
              Federation Limited.
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5" className="w-100 text-primary mt-4">
          Objectives Of The Society
        </Typography>
        <ol className="fs-5 ps-0">
          <li className="fs-5">
            Adding more value to the Cooperative operation and bring new
            innovation to the system.
          </li>
          <li className="fs-5">
            Complimenting and supporting the component of Cooperative
            organization.
          </li>
          <li className="fs-5">
            To promote Cooperative spirit among members work for the improvement
            of their living standards and encourage the development of the
            Cooperative Investment.
          </li>
          <li className="fs-5">
            To promote education and Cooperative Principles among young people
            in Ogun State.
          </li>
          <li className="fs-5">
            To encourage members to engage in the cultivation of farm crops,
          </li>
          <li className="fs-5">
            To encourage the participation and development of youth across Oun
            State.
          </li>
          <li className="fs-5">
            To arrange for the sale of member' produce to the best advantage.
          </li>
          <li className="fs-5">
            To make available members the knowledge of modern agricultural
            technology.
          </li>
          <li className="fs-5">
            To encourage members to set up agro-allied agro-allied industries.
          </li>
          <li className="fs-5">
            To encourage thrift, mutual help and self-help among members, and
            provide means whereby savings may be made without their being
            removed from members' control;
          </li>
          <li className="fs-5">
            To grant loans (at reasonable interest rate) to members for
            productive purposes.
          </li>
          <li className="fs-5">
            To engage in the purchase and distribution of customer goods to
            members; and
          </li>
          <li className="fs-5">
            To raise capital and do such other things that are reasonable for
            the attainment of these objectives.
          </li>
        </ol>
      </Container>
      <Container className="d-flex flex-column align-items-center my-6 w-75">
        <Grid container>
          <Grid item xs={12} lg={4} className="d-flex">
            <Typography className="fs-5 text-primary">Call:</Typography>
            <Typography className="fs-5">
              &nbsp;&nbsp;Co-op! Co-op!! Co-op!!!
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} className="d-flex">
            <Typography className="fs-5 text-secondary">Response: </Typography>
            <Typography className="fs-5">&nbsp;&nbsp;Co-operative</Typography>
          </Grid>
        </Grid>
        <Grid container className="mt-2">
          <Grid item xs={12} lg={4} className="d-flex">
            <Typography className="fs-5 text-primary">Call:</Typography>
            <Typography className="fs-5">
              &nbsp;&nbsp;Youth Cooperative!
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} className="d-flex">
            <Typography className="fs-5 text-secondary">Response: </Typography>
            <Typography className="fs-5">
              &nbsp;&nbsp;Strength of the moment.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="mt-2">
          <Grid item xs={12} lg={4} className="d-flex">
            <Typography className="fs-5 text-primary">Call:</Typography>
            <Typography className="fs-5">
              &nbsp;&nbsp;Youth Cooperative!
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} className="d-flex">
            <Typography className="fs-5 text-secondary">Response: </Typography>
            <Typography className="fs-5">
              &nbsp;&nbsp;Leaders of tomorrow
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default About;
