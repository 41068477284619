import { useEffect, useState } from "react";
import axios from "axios";

import { Container } from "reactstrap";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import { Loading } from "@nextui-org/react";
// import IconButton from '@mui/material/IconButton';
// import InfoIcon from '@mui/icons-material/Info';

import fof from "../assets/img/leaders.jpg";
import NavbarComp from "../Components/Navs/topNav";
import Footer from "../Components/Navs/footer";

const Directors = () => {
  const [directors, setDirectors] = useState(null);
  const [directorStatus, setDirectorsStatus] = useState(false);

  const getDirectors = async () => {
    try {
      await axios.get(`https://youthapi.ogscofed.coop/officers`).then((res) => {
        setDirectors(res.data);
        setDirectorsStatus(true);
      });
    } catch (error) {
      setDirectorsStatus(false);
    }
  };

  useEffect(() => {
    getDirectors();
  }, []);
  return (
    <>
      <NavbarComp />
      <div
        className="page-header min-height-300 mt-4 justify-content-center flex-column"
        style={{ backgroundImage: `url('${fof}')` }}
      >
        <Typography
          variant="h1"
          className="z-index-1 text-white"
          sx={{ fontSize: {xs:"5em" , md:"6em"} }}
        >
          Our Leaders
        </Typography>
        <Typography variant="h6" className="z-index-1 text-white">
          Telling our inspiring story
        </Typography>
        <span className="mask bg-gradient-primary opacity-8"></span>
      </div>
      <Container>
        {directorStatus ? (
          <>
            <ImageList
              //   sx={{ width: 500, height: 450 }}
              //   variant="woven"
              //   cols={}
              gap={8}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)!important",
                  sm: "repeat(2, 1fr)!important",
                  md: "repeat(3, 1fr)!important",
                  lg: "repeat(4, 1fr)!important",
                },
                overflowY: "visible",
                overflowX: "clip",
                mt: "2em",
              }}
            >
              {directors.map((item) => (
                <ImageListItem key={item.id}>
                  <img
                    srcSet={`https://youthapi.ogscofed.coop/${item.profile_image}`}
                    src={`https://youthapi.ogscofed.coop/${item.profile_image}`}
                    alt={item.position}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={`${item.coop_member.first_name} ${item.coop_member.last_name}`}
                    subtitle={item.position}
                    position="top"
                    sx={{
                      "& .MuiImageListItemBar-title": {
                        wordWrap: "break-word",
                        whiteSpace: "break-spaces",
                      },
                    }}
                    // actionIcon={
                    //   <IconButton
                    //     sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    //     aria-label={`info about ${item.position}`}
                    //   >
                    //     <InfoIcon />
                    //   </IconButton>
                    // }
                  />
                  <ImageListItemBar
                    position="bottom"
                    title={item.bio}
                    sx={{
                      "& .MuiImageListItemBar-title": {
                        wordWrap: "break-word",
                        whiteSpace: "break-spaces",
                      },
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </>
        ) : (
          <div className="w-100 my-10 h-100 d-flex align-items-center justify-items-center justify-content-center">
            <Loading size="xl" />
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Directors;
