import React from "react";

import { useHistory } from "react-router-dom";
import "../index.css";

import {
  Container,
  Row,
  Col,
} from "reactstrap";

import { Image, Text, Button, Card, Divider } from "@nextui-org/react"

import hero from "../static/images/hero-01.png"
import empowerment from "../static/images/empowerment2.png"
import financial from "../static/images/financial.png"
import meetings from "../static/images/meetings.png"
import announce from "../static/images/announcement.png"
import push from "../static/images/pushing.png"
import carry from "../static/images/carry.png"

import Footer from "../Components/Navs/footer";
import NavbarComp from "../Components/Navs/topNav";


const Home = () => {
    const history = useHistory()
    const registerClick = () => {
      history.push('/account/register');
    }

    return (
      <main className="loginForm position-relative max-height-vh-100 h-100">
      <NavbarComp />
      <Container className="mt-8">
        <Row className="mb-5 home-hero">
          <Col xs={12} sm={7} className="home-text-hero" >
            <Row>
              <Text b h2 className="size-2">The Youth Cooperative Society of now and of the future.</Text>
              <Text h4 weight={"normal"} className="size-1">We are committed to working together to 
              create a brighter tomorrow for ourselves and for generations to come.</Text>
            </Row>
            <Row className="center">
              <Col xs={12} sm={12} md={6}>
                <Button size={"lg"} className="mt-2 hero-but w-100" onClick={registerClick}>JOIN US</Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={5}>
            <Image src={hero} alt="ogscofed-hero" width={"100%"} height={"100%"} objectFit="cover"/>          
          </Col>
        </Row>
        <Row className="my-5 py-5 no-pad">
          <Col xs={12} sm={12} lg={4} className="align-self-center t-center">
            <Image src={push} width={"25%"} height={"25%"} className="mx-0 marg-auto"/>
            <Text b h4>Empowerment begins with skills and knowledge</Text>
            <Text h5 weight={"light"} className="text-muted">There are opportunities, workshops and training sessions
            to help youth members to improve their skills and gain knowledge on various topics.</Text>
          </Col>
          <Col xs={0} sm={0} lg={8} className="dis-none">
            <Card variant="bordered" css={{ height:"30rem" }}>
              <Image src={empowerment} alt="empowerment-section" width={"100%"} height={"100%"} objectFit="cover"/>
            </Card>
          </Col>
        </Row>
        <Row className="my-5 py-5 no-pad">
          <Col xs={12} sm={12} lg={4} className="align-self-center t-center">
            <Image src={carry} width={"25%"} height={"25%"} className="mx-0 marg-auto"/>
            <Text b h4>Saving money and investing wisely</Text>
            <Text h5 weight={"light"} className="text-muted">To provide information on financing options 
            such as loans and funding. And also resources on online financial management tools through 
            savings and investment.</Text>
          </Col>
          <Col xs={0} sm={0} lg={8} className="dis-none">
            <Card  variant="bordered" css={{ height:"30rem" }}>
              <Image src={financial} alt="financial-section" width={"100%"} height={"100%"} objectFit="cover"/>
            </Card>
          </Col>
        </Row>
        <Row className="my-5 py-5 no-pad">
          <Col xs={12} sm={12} lg={4} className="align-self-center t-center">
            <Image src={announce} width={"25%"} height={"25%"} className="mx-0 marg-auto"/>
            <Text b h4>Information exchange and Engagements</Text>
            <Text h5 weight={"light"} className="text-muted">Planning and strategiizing for a better community
             and it's people by dicussing goals and priorities in accordance with a clear vision 
             and direction for the future.</Text>
          </Col>
          <Col xs={0} sm={0} lg={8} className="dis-none">
            <Card variant="bordered" css={{ height:"30rem" }}>
              <Image src={meetings} alt="meetings-section" width={"100%"} height={"100%"} objectFit="cover"/>
            </Card>
          </Col>
        </Row>
        <Divider variant="middle" />
        <Row className="my-5 py-5 justify-content-center">
          <Col xs={12} className="align-self-center text-center w-50 text-width">
            <Text></Text>
            <Text b h4>Societal sustainability and nation building</Text>
            <Text h5 weight={"light"} className="text-muted">Levaraging on opportunities and resources as cooperatives 
            in bringing about the big difference we need in our communities, through leadership and 
            interactions.</Text>
          </Col>
        </Row>        
      </Container>      
      <Footer />
      </main>
    );
}

export default Home;